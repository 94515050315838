import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  Menu,
  styled,
  InputBase,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  IconButton,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DriveFolder from "../../commonComponents/BsiDrive/DriveFolder";
import DriveFiles from "../../commonComponents/BsiDrive/DriveFiles";
import NewFolderModal from "../../commonComponents/BsiDrive/NewFolderModal";
import Modals from "../../commonComponents/Modals";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import RenameModal from "../../commonComponents/BsiDrive/RenameModal";
import MoveModal from "../../commonComponents/BsiDrive/MoveModal";
import ShareModal from "../../commonComponents/BsiDrive/ShareModal";
import ShareSelectedBoardModal from "../../commonComponents/BsiDrive/ShareSelectedBoardModal";
import { useFetchRequirementBoardQuery } from "../../redux/api/Requirement Boards/getRequirementBoardList";
import ShareSolutionChatModal from "../../commonComponents/BsiDrive/ShareSolutionChatModal";
import { toast } from "react-toastify";
import { useUploadDocumentMutation } from "../../redux/api/BsiDrive/uploadDocument";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../../commonComponents/BsiDrive/fileUpload";
import { useFetchDriveItemListQuery } from "../../redux/api/BsiDrive/getDriveFolderList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import CancelIcon from "@mui/icons-material/Cancel";
import NoDataPage from "../../utils/Error/noDataFound";
import { resetRefetchDocument, resetRefetchRequirementBoard } from "../../redux/features/refetch";
import { useEffect } from "react";
const BsiDrive = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginLeft: 0,
    marginBottom: 0,
    width: "300px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 180px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.9, 0, 1.9, 1.7),
      // Increase paddingRight to create space for the icon
      paddingRight: `calc(1em + ${theme.spacing(6)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [industryFilterId, setIndustryFilterId] = useState("");
  const [industry, setIndustry] = useState("");
  const [techValue, setTechValue] = useState("");
  const [tech, setTech] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [documentName, setDocumentName] = useState("");

  const [sortOption, setSortOption] = useState("asc");
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(!openSort);
  };


  const handleCloseSort = (event) => {
    setAnchorElSort(null);
    setOpenSort(false);
    setSelectedSortOption(event.target.value);
  };


  // Searching logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };
  const {
    data: driveItemData,
    error: driveItemError,
    isLoading: isDriveItemLoading,
    refetch,
  } = useFetchDriveItemListQuery({
    parentId: documentId,
    searchParams: searchValue,
    sortOption: sortOption,
  });
    const refetchDocument = useSelector((state) => state.refetch.refetchDocument);
    useEffect(() => {
      if (refetchDocument) {
        refetch().finally(() => {
          dispatch(resetRefetchDocument());
        });
      }
    }, [refetchDocument, refetch, dispatch]);

  const handleSorting = (event) => {
    setSortOption(event.target.value);
    setOpenSort(false);
    refetch();
  };

  const handleDocumentSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetch();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Start loading
        await refetch(); // Await refetch to ensure completion before proceeding
        if (driveItemError || driveItemData?.statusCode === 404) {
          const errorMessage =
            driveItemData?.message || "Failed to load search result";
          showToastMessage(errorMessage, "error");
        }
      } catch (error) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };
  useEffect(() => {
    if (isDriveItemLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isDriveItemLoading, dispatch]);

  // const handleSorting = (event) => {
  //   const { value } = event.target;
  //   setSortOption(value);
  //   handleCloseSort(); // Close the menu after selection
  // };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyItems: "center",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        {/* upload files */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "inherit",
              p: "11px",
              boxShadow: "none",
              px: 5,
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("md")]: {
                px: 2.5,
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                mt: 1,
              },
            }}
            onClick={handleOpenUploadModal}
          >
            <FileUploadOutlinedIcon
              sx={{
                fontSize: "20px",
                marginRight: "8px",
              }}
            />
            Upload Files
          </Button>
        </Box> */}
         <Box>
          
         </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",

            [theme.breakpoints.down("md")]: {
              width:'60%',
              // gap:"10px"
            },
            [theme.breakpoints.down("sm")]: {
              width:'100%',
              gap:"10px"
            },
          }}
        >
          <Search
            sx={{
              backgroundColor: "extraLTheme.main",
              mr: 2,
              ml: "auto",
              [theme.breakpoints.down("sm")]: {
                mr: 0,
                // ml: "auto",
              },
            }}
          >
            <StyledInputBase
              id="searchBox"
              placeholder="Search file or folder"
              inputProps={{ "aria-label": "search" }}
              value={searchParams}
              onChange={handleInputChange}
              onKeyDown={handleDocumentSearch}
              autoFocus
            />
            {searchParams ? (
              <IconButton
                onClick={handleClearSearch}
                sx={{ position: "absolute", right: 0 }}
              >
                <CancelIcon sx={{ color: "lightSecondary.main" }} />
              </IconButton>
            ) : (
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "lightSecondary.main" }} />
              </SearchIconWrapper>
            )}
          </Search>

          <Box
            sx={{
              mr: 2,
              [theme.breakpoints.down("sm")]: {
                width: "60%",
                mr: "0.5rem",
              },
            }}
          >
            <Button
              id="basic-button"
              aria-controls={openSort ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? "true" : undefined}
              onClick={handleClickSort}
              sx={{
                background: "#fff",
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                textTransform: "inherit",
                fontWeight: "600",
                p: "12px 20px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              Sort
              <KeyboardArrowDownOutlined sx={{}} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={handleCloseSort}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  borderRadius: "10px",
                  border: "solid 1px #106EED",
                },
              }}
            >
              <MenuItem
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  },
                }}
                // onClick={handleSorting}
              >
                <FormControlLabel
                  value="asc"
                  control={
                    <Radio
                      sx={{
                        paddingY: 0,
                        color: "#2A589C",
                      }}
                      checked={sortOption === "asc"}
                      onChange={handleSorting}
                    />
                  }
                  label="Name A-Z"
                />
              </MenuItem>
              <MenuItem
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  },
                }}
                // onClick={handleSorting}
              >
                <FormControlLabel
                  value="desc"
                  control={
                    <Radio
                      sx={{ paddingY: 0, color: "#2A589C" }}
                      checked={sortOption === "desc"}
                      onChange={handleSorting}
                    />
                  }
                  label="Name Z-A"
                />
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Stack>

      {/* Folders Section */}

      <DriveFolder
        showToastMessage={showToastMessage}
        searchValue={searchValue}
        refetch={refetch}
        setDocumentId={setDocumentId}
        documentId={documentId}
        driveItemData={driveItemData}
      />

      {/* Files Section */}
      <DriveFiles
        showToastMessage={showToastMessage}
        searchValue={searchValue}
        refetch={refetch}
        setDocumentId={setDocumentId}
        documentId={documentId}
        driveItemData={driveItemData}
      />
      {/* <Modals
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        modalWidth={500}
      >
        <FileUpload
          handleClose={handleCloseUploadModal}
          handleCloseUploadModal={handleCloseUploadModal}
          showToastMessage={showToastMessage}
          documentId={documentId}
        />
      </Modals> */}
    </>
  );
};

export default BsiDrive;
