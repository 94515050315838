import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setTaskDescription,
  setTaskDueDate,
  setTaskName,
  setTaskPriority,
  setTaskStartDate,
  setTaskStatus,
} from "../../redux/features/MyProjects/editTaskSlice";
import { useCreateTaskMutation } from "../../redux/api/MyProjects/createTask";
import {
  setRefetchProjectDetail,
  setRefetchProjectList,
} from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useEditTaskMutation } from "../../redux/api/MyProjects/editTask";
import shortid from "shortid";

const useStyles = makeStyles({
  datePicker: {
    "& > div.MuiInputBase-root > input": {
      padding: "10px 0 10px 14px !important",
      borderRadius: "4px",
      backgroundColor: "#ECF6FF",
      border: "solid 1px #DBEBF8",
      width: "100% ",
      color: "#2A589",
    },
  },
});

const EditTask = ({ showToastMessage, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  //   States
  const {
    taskData,
    taskName,
    taskDescription,
    taskStatus,
    taskPriority,
    taskStartDate,
    taskDueDate,
  } = useSelector((state) => state.editTaskSlice);

  const { milestoneId, projectId, taskId } = useSelector(
    (state) => state.projectSlice
  );
  const userId = localStorage.getItem("userId");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  // Validation state
  const [errors, setErrors] = useState({
    taskName: false,
    taskDescription: false,
    taskStatus: false,
    taskPriority: false,
    taskStartDate: false,
    taskDueDate: false,
  });

  // api

  // add task
  const [editTask, { isLoading: editTaskLoading, isError: editTaskError }] =
    useEditTaskMutation();

  // Functions

  // functions for attach document
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(newFiles);
    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({
        id: shortid.generate(),
        filename: file.name,
        filetype: file.type,
        fileimage: URL.createObjectURL(file),
        datetime: file.lastModifiedDate.toLocaleString("en-IN"),
        filesize: filesizes(file.size),
      })),
    ]);
  };

  const handleDeleteFile = (id, filename) => {
    // if (window.confirm("Are you sure you want to delete this file?")) {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setFiles((prevFiles) =>
      Array.from(prevFiles).filter((file) => file.name !== filename)
    );
    // }
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // function to handle task name
  const handleTaskName = (event) => {
    const inputValue = event.target.value;

    // Check for errors and set the corresponding message
    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: "This field is required.",
      }));
    } else if (inputValue.length > 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: "Limit exceeded",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: false,
      }));
    }

    dispatch(setTaskName(inputValue));
  };

  // function to handle task name
  const handleTaskDescription = (inputValue) => {
    if (inputValue.length > 250) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDescription: true,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDescription: false,
      }));
    }
    dispatch(setTaskDescription(inputValue));
  };
  // function to handle task status
  const handleTaskStatus = (event) => {
    const inputValue = event.target.value;
    dispatch(setTaskStatus(inputValue));
  };
  // function to handle task priority
  const handleTaskPriority = (event) => {
    const inputValue = event.target.value;
    dispatch(setTaskPriority(inputValue));
  };
  const handleTaskStartDate = (date) => {
    // Check if the date is valid
    if (!dayjs(date).isValid()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskStartDate: true,
      }));
      return;
    }

    // Check if selected date is before today
    // if (dayjs(date).isBefore(dayjs(), "day")) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     taskStartDate: true,
    //   }));
    // } else {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     taskStartDate: false,
    //     taskDueDate: dayjs(taskDueDate).isBefore(dayjs(date), "day"), // Update due date error if necessary
    //   }));
    // Dispatch the date as an ISO string to Redux store
    dispatch(setTaskStartDate(dayjs(date).toISOString()));
    // }
  };

  const handleTaskDueDate = (date) => {
    // Check if the date is valid
    if (!dayjs(date).isValid()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: true,
      }));
      return;
    }

    // Check if selected date is before today
    // if (dayjs(date).isBefore(dayjs(), "day")) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     taskDueDate: true,
    //   }));
    // }
    // Check if due date is before start date
    else if (dayjs(date).isBefore(dayjs(taskStartDate), "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: false,
      }));
      // Dispatch the date as an ISO string to Redux store
      dispatch(setTaskDueDate(dayjs(date).toISOString()));
    }
  };

  const editTasks = async () => {
    let hasError = false;
    const newErrors = {
      taskName: false,
      taskStartDate: false,
      taskDueDate: false,
    };

    // Validate task name
    if (taskName.trim() === "") {
      newErrors.taskName = "This field is required.";
      hasError = true;
    } else if (taskName.length > 100) {
      newErrors.taskName = "Character limit exceeded (max 100).";
      hasError = true;
    }

    // Validate task description length
    if (taskDescription.length > 250) {
      newErrors.taskDescription = "Character limit exceeded (max 250).";
      hasError = true;
    }

    // Validate task start date
    // if (!taskStartDate || dayjs(taskStartDate).isBefore(dayjs(), "day")) {
    //   newErrors.taskStartDate = "The start date cannot be in the past.";
    //   hasError = true;
    // }

    // Validate task due date
    if (!taskDueDate) {
      newErrors.taskDueDate = "This field is required.";
      hasError = true;
    }
    // else if (dayjs(taskDueDate).isBefore(dayjs(), "day")) {
    //   newErrors.taskDueDate = "The due date cannot be in the past.";
    //   hasError = true;
    // }
    else if (dayjs(taskDueDate).isBefore(dayjs(taskStartDate), "day")) {
      newErrors.taskDueDate =
        "The due date cannot be earlier than the start date.";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    // const taskDetail = [];
    const formData = new FormData();
    const taskDetail = {
      _id: taskId,
      name: taskName,
      description: taskDescription,
      start_date: taskStartDate,
      due_date: taskDueDate,
      priority: taskPriority,
      status: taskStatus,
      created_by: taskData?.created_by,
      drive_ids: taskData?.drive_ids,
      display_order: taskData?.display_order,
      createdAt: taskData?.createdAt,
      updatedAt: taskData?.updatedAt,
    };
    formData.append("milestone_id", milestoneId);
    formData.append("task_id", taskId);
    formData.append("owner_id", userId);
    formData.append("tasks", JSON.stringify(taskDetail));
    files.forEach((file) => formData.append("files", file));
    try {
      const response = await editTask({
        projectId,
        formData,
      });

      const successMessage = response?.data?.message || "Task edited";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchProjectDetail());
      dispatch(setRefetchProjectList(true));
      handleClose();

      if (response?.statusCode === 500) {
        showToastMessage(successMessage, "error");
      }
    } catch (editTaskError) {
      const errorMessage = editTaskError?.message || "Failed to edit task";
      showToastMessage(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (editTaskLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [editTaskLoading, dispatch]);

  return (
    <>
      <Grid
        container
        item
        rowSpacing={3}
        columnSpacing={5}
        xs={12}
        md={12}
        lg={12}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Task Name*
          </Typography>
          <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            value={taskName}
            onChange={handleTaskName}
            style={{
              margin: 0,
              width: "100%",
            }}
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "extraLTheme.main",
              border: "solid 1px #DBEBF8",
              m: 0,
              "& .MuiInputBase-root": {
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                pt: 1.5,
                pb: 1.5,
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  border: "none",
                },
              "& .MuiInputBase-root:hover:before": {
                border: "none",
              },
              "&:hover": {
                borderColor: "#DBEBF8",
              },
              "& .MuiInputBase-root:before": {
                border: "none",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
              "& .MuiInputBase-root.Mui-focused .MuiIconButton-root": {
                backgroundColor: "transparent !important", // Ensure it does not inherit focus styles
              },
            }}
          />
          {errors.taskName && (
            <Typography
              variant="body2"
              color="error"
              sx={{ fontSize: "12px", mt: 0.5 }}
            >
              {errors.taskName}
            </Typography>
          )}
        </Grid>
        {/* Start Date */}
        <Grid item xs={12} md={6} lg={6} className="customDatePickerContainer">
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Start Date*
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={taskStartDate ? dayjs(taskStartDate) : null}
              onChange={handleTaskStartDate}
              className={`${classes.datePicker} selectDate`}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "extraLTheme.main",
                  "& fieldset": {
                    border: "none", // Remove the default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  backgroundColor: "extraLTheme.main", // Icon background color
                },
              }}
            />
            {errors.taskStartDate && (
              <Typography
                variant="body2"
                color="error"
                sx={{ fontSize: "12px", mt: 0.5 }}
              >
                The start date cannot be in the past
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
        {/* Due Date */}
        <Grid item xs={12} md={6} lg={6} className="customDatePickerContainer">
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Due Date*
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={taskDueDate ? dayjs(taskDueDate) : null}
              onChange={handleTaskDueDate}
              className={`${classes.datePicker} selectDate`}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  // width: "56.6ch",
                  backgroundColor: "extraLTheme.main",
                  "& fieldset": {
                    border: "none", // Remove the default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  backgroundColor: "extraLTheme.main", // Icon background color
                },
              }}
            />
            {errors.taskDueDate && (
              <Typography
                variant="body2"
                color="error"
                sx={{ fontSize: "12px", mt: 0.5 }}
              >
                {dayjs(taskDueDate).isBefore(dayjs(taskStartDate), "day")
                  ? "The due date cannot be earlier than the start date."
                  : "The due date cannot be in the past."}
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
        {/* Task Description */}
        <Grid item xs={12} md={6} lg={12} xl={12}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Task Description
          </Typography>
          {/* <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            value={taskDescription}
            onChange={handleTaskDescription}
            style={{
              margin: 0,
              width: "100%",
            }}
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "extraLTheme.main",
              border: "solid 1px #DBEBF8",
              m: 0,
              "& .MuiInputBase-root": {
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                pt: 1.5,
                pb: 1.5,
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  border: "none",
                },
              "& .MuiInputBase-root:hover:before": {
                border: "none",
              },
              "&:hover": {
                borderColor: "#DBEBF8",
              },
              "& .MuiInputBase-root:before": {
                border: "none",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
            }}
          /> */}
          <Box
            style={{
              width: "100%",
              borderRadius: "4px",
              border: "solid 1px #DBEBF8",
              backgroundColor: "extraLTheme.main",
              marginBottom: 10,
            }}
            sx={{
              "& .ql-editor": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                width: "100%",
                maxWidth: 1000,
              },
              "& .ql-toolbar": {
                width: "100%",
                // borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "solid 1px #DBEBF8",
                height: "40px",
              },
              "& .ql-formats": {
                mb: 3,
              },
              "& .ql-container": {
                width: "100%",
                // borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "solid 1px #DBEBF8",
                maxWidth: "100%",
              },
            }}
          >
            <ReactQuill
              value={taskDescription}
              onChange={handleTaskDescription}
              placeholder={"Write something here..."}
              theme="snow"
              style={{
                height: "100px",
                backgroundColor: "extraLTheme.main",
                fontSize: "1.9rem",
                fontWeight: "600",
                color: "lightSecondary.main",
                borderRadius: "4px",
                width: "100%",
                maxWidth: "100%",
              }}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              formats={["bold", "italic", "underline", "list", "bullet"]}
            />
          </Box>
          {errors.taskDescription && (
            <Typography
              variant="body2"
              color="error"
              sx={{ fontSize: "12px", mt: 0.5 }}
            >
              Character limit exceeded (max 250).
            </Typography>
          )}
        </Grid>
        {/* Priority */}
        <Grid item xs={12} md={6} lg={6} xl={6} sx={{mt:4}}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Priority
          </Typography>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              IconComponent={KeyboardArrowDownOutlinedIcon}
              value={taskPriority}
              onChange={handleTaskPriority}
              sx={{
                background: "#ECF6FF",
                "& .MuiSelect-select": {
                  minHeight: "20px",
                  padding: "11px 14px",
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  border: "solid 1px #DBEBF8",
                },
                "& .MuiSelect-icon": {
                  color: "lightSecondary.main",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                },
              }}
            >
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Status */}
        <Grid item xs={12} md={6} lg={6} xl={6} sx={{mt:4}}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Status
          </Typography>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              IconComponent={KeyboardArrowDownOutlinedIcon}
              value={taskStatus}
              onChange={handleTaskStatus}
              sx={{
                background: "#ECF6FF",
                "& .MuiSelect-select": {
                  minHeight: "20px",
                  padding: "11px 14px",
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  border: "solid 1px #DBEBF8",
                },
                "& .MuiSelect-icon": {
                  color: "lightSecondary.main",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                },
              }}
            >
              <MenuItem value={"inprogress"}>In-Progress</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Attach Document */}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            mt: 2,
            mb: 2,
          }}
        >
          <ListItemButton
            onClick={() => document.getElementById("fileInput").click()}
            sx={{
              border: "solid 1px #A2C2F1",
              background: "#ECF6FF",
              padding: "14px 20px",
              borderRadius: "10px",
              minWidth: "10rem",
              maxWidth: "15rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "lightSecondary.main",
              fontWeight: "600",
              fontSize: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FileUploadOutlinedIcon
                sx={{
                  mr: 1.2,
                }}
              />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Attach Document
              </Typography>
            </Box>
          </ListItemButton>
          {/* Hidden File Input */}
          <input
            id="fileInput"
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Grid>
        {/* Selected Files Grid */}
        {selectedFiles.length > 0 && (
          <>
            {selectedFiles.map(
              ({ id, filename, fileimage, filesize }) => (
                <Grid item xs={12} md={6} lg={6} key={id}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        mr: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.palette.grey[200],
                      }}
                    >
                      {filename.match(/\.(jpg|jpeg|png|gif|svg)$/i) ? (
                        <img
                          src={fileimage}
                          alt={filename}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      ) : (
                        <Typography variant="body2">File</Typography>
                      )}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body2" noWrap>
                        {filename}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Size: {filesize} 
                      </Typography>
                    </Box>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        onClick={() => handleDeleteFile(id, filename)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              )
            )}
          </>
        )}

        {/* Submit Button */}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            textAlign: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            // disabled={editTaskLoading}
            sx={{
              textTransform: "inherit",
              p: "10px 20px",
              boxShadow: "none",
              fontWeight: "600",
              width: "200px",
            }}
            onClick={editTasks}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditTask;
