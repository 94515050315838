
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";


const baseUrl =process.env.REACT_APP_base_URL;

const createProject = createApi({
    reducerPath: 'createNewProject',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createProject: builder.mutation({
            query: ({ projectName , isActive , selectedSolutionId , userId , selectedBusinessUserId , selectedBoardId , selectedTeamMembers}) => ({
                url:'projects/create',
                method: 'POST',
                body: {
                    plan_name : projectName,
                    is_active : isActive,
                    solution_id : selectedSolutionId,
                    created_by : userId,
                    business_user_id : selectedBusinessUserId || userId, 
                    requirement_board_id : selectedBoardId || selectedSolutionId,
                    team_members : selectedTeamMembers
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useCreateProjectMutation } = createProject;
export default createProject;
