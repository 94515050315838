import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Badge,
  MenuItem,
  Typography,
  Menu,
  IconButton,
  styled,
  InputBase,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  Card,
  CardContent,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  ListItemAvatar,
  ListItemButton,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CountUp from "react-countup";
import { getImages } from "../../commonComponents/const";
import Modals from "../../commonComponents/Modals";
import EditTask from "./editTask";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useFetchProjectDetailQuery } from "../../redux/api/MyProjects/getProjectDetail";
import { ToolTwoTone } from "@ant-design/icons";
import UploadDocument from "./uploadDocument";
import {
  reorderMilestones,
  reorderTasks,
  setMilestoneId,
  setProjectDetailData,
  setProjectId,
  setTaskId,
} from "../../redux/features/MyProjects/projectSlice";
import { resetRefetchProjectDetail } from "../../redux/features/refetch";
import AddTask from "./addTask";
import { useDragAndDropMutation } from "../../redux/api/MyProjects/dragAndDrop";
import { useReorderTaskMutation } from "../../redux/api/MyProjects/reorderTask";
import { setTaskData } from "../../redux/features/MyProjects/editTaskSlice";
import { setMilestoneData } from "../../redux/features/MyProjects/editMilestoneSlice";
import ReactApexChart from "react-apexcharts";
import NoMilestoneScreen from "../../commonComponents/NoDataScreens/NoMilestone";
import NoTaskScreen from "../../commonComponents/NoDataScreens/NoTask";
import MilestoneSkeleton from "../../commonComponents/Skeleton/MilestoneSkull";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../../styles/toggleStyle.css"

const ITEM_HEIGHT = 48;
const ProjectDetail = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Get the encoded projectId from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const encodedProjectId = queryParams.get("projectId");
  
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    return `${month}. ${day} ${year}`;
  };
  // Common Box styles
  const boxStyle = {
    borderRadius: "12px",
    padding: "20px",
    textAlign: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };
  const milestoneStatusColor = {
    "To Do": "#6B7280", // Grey
    "In Progress": "#2563EB", // Blue
    Pending: "#D92F25", // Red
    Completed: "#10B981", // Green
  };
  const taskStatusColor = {
    "In Progress": "#2563EB", // Blue
    Pending: "#D92F25", // Red
    Completed: "#10B981", // Green
  };

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  function getColor(status) {
    switch (status) {
      case "pending":
        return "#BA8E23"; // Light yellow
      case "in_progress":
        return "#75DCD6"; // Light cyan
      case "overdue":
        return "#FF0000"; // Red
      default:
        return "#888"; // Default gray
    }
  }
  const { projectDetailData } = useSelector(
    (state) => state.projectSlice
  );
  const projectId = encodedProjectId ? atob(encodedProjectId) : null;
  const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [animate, setAnimate] = useState(false);
  const open = Boolean(anchorEl);

  // Api Calls

  // project listing api
  const {
    data: projectDetail,
    isError: projectDetailError,
    isLoading: projectDetailLoading,
    refetch: refetchProjectDetail,
  } = useFetchProjectDetailQuery({ projectId });
  // Project Name

  // useEffect(()=>{
  // if(!projectDetail || typeof projectDetail === "undefined" ){
  //   navigate(-1)
  // }
  // },[projectDetail])

  const projectName = projectDetail?.plan_name || ""

  // Total Milestone count
  const totalMilestones = projectDetail?.milestones?.length || 0;

  // Pending Milestone count
  const pendingMilestones =
    projectDetail?.milestones?.filter((milestone) => {
      // Check if all tasks in the milestone have the status "pending"
      return milestone?.tasks?.every((task) => task.status === "pending");
    }).length || 0;

  // Completed Milestone count
  const completedMilestones = projectDetail?.completedMilestones;
  const inProgressMilestones =
    projectDetail?.milestones.length -
    (projectDetail?.completedMilestones + pendingMilestones);

  // TotalTask Count
  const totalTasks = projectDetail?.milestones?.reduce((total, milestone) => {
    return total + (milestone?.tasks?.length || 0);
  }, 0);

  // Total tasks with status 'in_progress'
  const totalInProgressTasks = projectDetail?.milestones?.reduce(
    (total, milestone) => {
      const inProgressTasks =
        milestone?.tasks?.filter((task) => task.status === "in-progress") || [];
      return total + inProgressTasks.length;
    },
    0
  );

  // Total tasks with status 'pending'
  const totalPendingTasks = projectDetail?.milestones?.reduce(
    (total, milestone) => {
      const inProgressTasks =
        milestone?.tasks?.filter((task) => task.status === "pending") || [];
      return total + inProgressTasks.length;
    },
    0
  );
  // Total tasks with status 'completed'
  const totalCompletedTasks = projectDetail?.milestones?.reduce(
    (total, milestone) => {
      const inProgressTasks =
        milestone?.tasks?.filter((task) => task.status === "completed") || [];
      return total + inProgressTasks.length;
    },
    0
  );

  const handleTabChange = (newValue) => {
    if (newValue !== null && newValue !== selectedTab) {
      // Trigger animation
      setAnimate(true);
      setTimeout(() => {
        setSelectedTab(newValue);
        setAnimate(false);
      }, 300); // Animation duration
    }
  };

  useEffect(() => {
    if (projectDetail && projectDetail?.milestones?.length > 0) {
      dispatch(setProjectDetailData(projectDetail?.milestones));
    }
  }, [projectDetail]);

  // set refetchProjectDetail in reduxStore
  const refetchProjectsDetails = useSelector(
    (state) => state.refetch.refetchProjectDetail
  );
  useEffect(() => {
    if (refetchProjectsDetails) {
      refetchProjectDetail().finally(() => {
        dispatch(resetRefetchProjectDetail());
      });
    }
  }, [refetchProjectsDetails, refetchProjectDetail, dispatch]);

  const [
    updateMilestoneIndex,
    {
      isLoading: updateMilestoneIndexLoading,
      isError: updateMilestoneIndexError,
    },
  ] = useDragAndDropMutation();
  const [
    updateTaskIndex,
    { isLoading: updateTaskIndexLoading, isError: updateTaskIndexError },
  ] = useReorderTaskMutation();

  // Function

  const handleClick = (event, documentUrl, documentName) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocumentUrl(documentUrl);
    setSelectedDocumentName(documentName);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDocumentUrl(null);
    setSelectedDocumentName(null);
  };
  const handleDownload = async () => {
    try {
      // Fetch the file from the server
      const response = await fetch(selectedDocumentUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set the href of the link to the blob URL
      link.href = url;

      // Choose the filename for the downloaded file
      link.download = selectedDocumentName || "downloaded-file"; // Ensure documentName is a string

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link from the DOM
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Free up memory
      }, 100); // Delay removal to ensure click event has been processed
      handleClose();
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // function for edit mile stone

  const handleEditMilestone = (milestoneData, milestoneId) => {
    navigate("/edit-mile-stone");
    dispatch(setMilestoneData(milestoneData));
    dispatch(setMilestoneId(milestoneId));
  };

  //  open add task
  const handleOpenAddTask = (milestoneId) => {
    setOpenAddTaskModal(true);
    dispatch(setMilestoneId(milestoneId));
  };
  const handleCloseAddTask = () => {
    setOpenAddTaskModal(false);
  };

  // Open edit task
  const handleOpenEditTask = (task, taskId, milestoneId) => {
    setOpenEditTaskModal(true);
    dispatch(setTaskData(task));
    dispatch(setTaskId(taskId));
    dispatch(setMilestoneId(milestoneId));
  };
  const handleCloseEditTask = () => {
    setOpenEditTaskModal(false);
  };

  const handleCreateMilestone = () => {
    navigate("/add-mile-stone");
    dispatch(setProjectId(projectId));
  };

  const handleGoBack = () => {
    navigate("/projects");
  };

  // Handle drag and drop logic

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return showToastMessage("Please drop inside the list", "error");
    }

    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }

    let updatedData;
    if (type === "milestone") {
      const reorderedList = [...projectDetailData];
      const [movedItem] = reorderedList.splice(source.index, 1);
      reorderedList.splice(destination.index, 0, movedItem);

      dispatch(
        reorderMilestones({
          sourceIndex: source.index,
          destinationIndex: destination.index,
        })
      );

      updateMilestoneIndex({
        respectiveId: projectId,
        updatedMilestones: reorderedList,
      })
        .then(() => {
          dispatch(setProjectDetailData(reorderedList));
        })
        .catch((error) => {
          // Attempt to recover by resetting to the last known good state
          dispatch(setProjectDetailData(projectDetailData));
          showToastMessage(
            "Failed to save changes. Recovering to previous state.",
            "warning"
          );
        });
    } else if (type === "task") {
      const sourceMilestoneIndex = parseInt(
        source.droppableId.split("-")[1],
        10
      );
      const destinationMilestoneIndex = parseInt(
        destination.droppableId.split("-")[1],
        10
      );

      const sourceMilestone = { ...projectDetailData[sourceMilestoneIndex] };
      const destinationMilestone =
        sourceMilestoneIndex === destinationMilestoneIndex
          ? sourceMilestone
          : { ...projectDetailData[destinationMilestoneIndex] };

      const sourceTasks = [...sourceMilestone.tasks];
      const destinationTasks =
        sourceMilestoneIndex === destinationMilestoneIndex
          ? sourceTasks
          : [...destinationMilestone.tasks];
      const [movedTask] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, movedTask);
      sourceMilestone.tasks = sourceTasks;
      if (sourceMilestoneIndex !== destinationMilestoneIndex) {
        destinationMilestone.tasks = destinationTasks;
      }

      updatedData = [...projectDetailData];
      updatedData[sourceMilestoneIndex] = sourceMilestone;
      if (sourceMilestoneIndex !== destinationMilestoneIndex) {
        updatedData[destinationMilestoneIndex] = destinationMilestone;
      }

      // Perform optimistic update
      dispatch(setProjectDetailData(updatedData));

      Promise.all([
        updateTaskIndex({
          // projectId: destinationMilestone._id,
          projectId: projectId,
          updatedTasks: destinationMilestone.tasks,
          milestoneId: destinationMilestone._id,
        }),
        // sourceMilestoneIndex !== destinationMilestoneIndex &&
        //   updateTaskOrder({
        //     milestoneId: destinationMilestone._id,
        //     updatedMilestone: destinationMilestone,
        //   })
      ])
        .then(() => {
          dispatch(setProjectDetailData(updatedData));
        })
        .catch((error) => {
          // Attempt to recover by resetting to the last known good state
          dispatch(setProjectDetailData(projectDetailData));
          showToastMessage(
            "Failed to save changes. Recovering to previous state.",
            "warning"
          );
        });
    }
  };

  const [selectedMilestone, setSelectedMilestone] = React.useState(null);

  const milestoneOptions = projectDetail?.milestones?.map((milestone) => ({
    value: milestone._id,
    label: milestone.name,
  }));

  // Filter tasks based on the selected milestone
  // Filter tasks based on the selected milestone
  const filteredTasks = selectedMilestone
    ? projectDetail?.milestones
        ?.find((milestone) => milestone._id === selectedMilestone)
        ?.tasks.map((task) => ({
          x: task.name || "Unnamed Task",
          y: [
            task.start_date ? formatDate(task.start_date) : null,
            task.due_date ? formatDate(task.due_date) : null,
          ],
        }))
    : [];
  // .filter((task) => task.y[0] && task.y[1]) || [] : []

  const statusColorMap = {
    inprogress: "#75DCD6", // Replace with your color for 'In Progress'
    pending: "#F7CB73", // Replace with your color for 'Pending'
    completed: "#7CDDA3", // Replace with your color for 'Overdue'
    overdue: "#FF0000", // Replace with your color for 'In Progress'
  };

  const options = {
    chart: {
      height: 390,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },

      horizontal: true, // Retained from the old options
      toolbar: {
        export: {
          // svg: {
          //   filename: `${projectName || "Project"}-project-chart`,
          // },
          png: {
            filename: `${projectName || "Project"}-project-chart`,
          },
        },
      },
    },
    colors: Object.values(statusColorMap),
    plotOptions: {
      bar: {
        horizontal: true,
        isDumbbell: true,
        // dumbbellColors: projectDetail?.milestones.flatMap((milestone) =>
        //   milestone.tasks.map((task) => {
        //     const currentDate = new Date().getTime();

        //     const isOverdue =
        //       task.due_date && new Date(task.due_date).getTime() < currentDate;

        //       return [
        //         isOverdue
        //           ? statusColorMap["overdue"] // Red for overdue tasks
        //           : statusColorMap[task.status] || "#888", // Default to status color
        //         isOverdue
        //           ? statusColorMap["overdue"]
        //           : statusColorMap[task.status] || "#888",
        //       ];
        //   })
        // ),
      },
    },

    title: {
      text: `Progress Chart (${projectName})`,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      customLegendItems: ["In-Progress", "Pending", "Completed", "Overdue"],
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Timeline",
      },
      labels: {
        format: " MMM. dd, yyyy", // Format for x-axis dates
      },
      categories: filteredTasks.map((task) => task.x), // Dynamic task categories
    },
    yaxis: {
      title: {
        text: "Milestones & Tasks", // Label for y-axis
      },
    },
    tooltip: {
      shared: false,
      intersect: false,
      x: {
        format: " MMM. dd, yyyy", // Tooltip date format
      },
      
    },
  };

  const series = [
    {
      name: "Tasks",
      data: filteredTasks,
    },
  ];

  useEffect(() => {
    if (projectDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [projectDetailLoading, dispatch]);
  return (
    <>
      <Grid
        container
        rowSpacing={{ md: 4.5, xs: 1 }}
        columnSpacing={2.75}
        sx={{ display: "flex" }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Stack
            direction="row"
            sx={{
              py: 0,
              px: 0,
              borderBottom: "solid 1px #DBEBF8",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={handleGoBack}
              sx={{
                marginRight: "5px",
                display: "inline-flex",
                p: 0,
                cursor: "pointer",
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: "lightSecondary.main",
                  fontSize: "32px",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "20px",
                  },
                }}
              />
            </IconButton>
            <Typography
              variant="body2"
              sx={{
                color: "secondary.main",
                fontSize: "1.4rem",
                fontWeight: "700",
                // pr: 0.7,
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.1rem",
                },
              }}
            >
              {projectName}
            </Typography>
          </Stack>
        </Grid>
        {/* Toggle Button */}
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Stack direction="row" sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={selectedTab}
              onChange={(event, newValue) => handleTabChange(newValue)}
              exclusive
            >
              <ToggleButton
                value="Overview"
                sx={() => ({
                  borderRadius: "10px",
                  textTransform: "none",
                  display:'flex',
                  gap:1,
                  backgroundColor:
                    selectedTab === "Overview"
                      ? "#073985  !important"
                      : "#fff  !important",
                  color:
                    selectedTab === "Overview"
                      ? "#fff !important"
                      : "lightSecondary.main !important",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === "Overview"
                        ? "#073985  !important"
                        : "#fff  !important",
                    color:
                      selectedTab === "Overview"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                <img
                  component="img"
                  src={selectedTab === "Overview" ? getImages("selected_overview_icon.svg") : getImages("unselected_overview_icon.svg")}
                  // alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography>Overview</Typography>
              </ToggleButton>
              <ToggleButton
                value="Progress"
                sx={() => ({
                  borderRadius: "10px",
                  textTransform: "none",
                  display:'flex',
                  gap:1,
                  backgroundColor:
                    selectedTab === "Progress"
                      ? "#073985  !important"
                      : "#fff  !important",
                  color:
                    selectedTab === "Progress"
                      ? "#fff !important"
                      : "lightSecondary.main !important",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === "Progress"
                        ? "#073985  !important"
                        : "#fff  !important",
                    color:
                      selectedTab === "Progress"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                <img
                  component="img"
                  src={selectedTab === "Progress" ? getImages("selected_chart_icon.svg") : getImages("unselected_chart_icon.svg")}
                  style={{
                    width: "20px",
                    height: "20px",
                    filter:
                      selectedTab === "Overview" ? "none" : "grayscale(100%)", // Apply grayscale when not selected
                  }}
                />
                <Typography>Progress</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
      {projectDetail?.milestones?.length === 0 ? (
        projectDetailLoading ? (
          <Grid container spacing={2}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Grid xs={12}>
                <MilestoneSkeleton key={index} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
            }}
          >
            <NoMilestoneScreen handleCreateMilestone={handleCreateMilestone} />
          </Box>
        )
      ) : (
        <>
          <SwitchTransition>
        <CSSTransition
          key={selectedTab}
          timeout={300}
          classNames="fade"
        >
          <Box
          // sx={{
          //   padding: "20px",
          //   opacity: animate ? 0 : 1,
          //   transform: animate ? "translateY(10px)" : "translateY(0)",
          //   transition: "opacity 300ms, transform 300ms",
          // }}
          >
            {selectedTab === "Overview" ? (
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                gap={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pl: 3,
                  mt: 3,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    // background: "#fff",
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: "#ecf6ff",
                        border: "1px solid #001a8c",
                        borderRadius: "10px",
                        transition: "all 0.5s ease-in-out 0s",
                        "&:hover": {
                          background: "#ffffff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="650"
                    sx={{ mb: 2, color: "secondary.main" }}
                  >
                    Milestones
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          // backgroundColor: "#FFB185"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={totalMilestones}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          //  backgroundColor: "#7AC5FF"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Pending
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={pendingMilestones}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          //  backgroundColor: "#75DCD6"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },

                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          In-Progress
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={inProgressMilestones}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          // backgroundColor: "#7CDDA3"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={completedMilestones}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Tasks Section */}
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  sx={{ 
                  p: 3, 
                  borderRadius: 3,
                  backgroundColor: "#ecf6ff",
                  border: "1px solid #001a8c",
                  borderRadius: "10px",
                  transition: "all 0.5s ease-in-out 0s",
                  "&:hover": {
                    background: "#ffffff",
                    boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                  },
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="650"
                    sx={{ mb: 2, color: "secondary.main" }}
                  >
                    Tasks
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          // backgroundColor: "#FFB185"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={totalTasks}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          // backgroundColor: "#7AC5FF"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Pending
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={totalPendingTasks}
                            duration={1.5}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          // backgroundColor: "#75DCD6"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          In-Progress
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={totalInProgressTasks}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          ...boxStyle,
                          //  backgroundColor: "#7CDDA3"
                          backgroundColor: "#fff",
                          "&:hover": {
                          background: "#ecf6ff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            mt: 1,
                            fontWeight: "600",
                            color: "secondary.main",
                          }}
                        >
                          <CountUp
                            start={0}
                            end={totalCompletedTasks}
                            duration={1.5}
                            separation=","
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              // merge data

              <Grid container p={2}>
                {/* Check if there are any milestones */}

                <Grid
                  item
                  xs={12}
                  sx={{
                    background: "#fff",
                    mt: 1,
                    borderRadius: "0.5rem",
                    p: 2,

                    "& .apexcharts-toolbar": {
                      padding: "1px",
                      maxWidth: "40px",
                      zIndex: 0,
                    },
                    "& .apexcharts-menu": {
                      padding: "1px",
                    },
                    "& .apexcharts-menu-item.exportCSV": {
                      display: "none  !important",
                    },
                    "& .apexcharts-menu-item.exportSVG": {
                      display: "none  !important",
                    },
                  }}
                >
                  <ReactApexChart
                    options={{
                      ...options,
                      xaxis: {
                        ...options.xaxis,
                        categories: projectDetailData?.flatMap(
                          (milestone) => [
                            milestone.name, // Add milestone name
                            ...milestone.tasks.map(
                              (task) => task.name || "Unnamed Task"
                            ), // Add task names
                          ]
                        ),
                      },
                      plotOptions: {
                        ...options.plotOptions,
                        bar: {
                          // ...options.plotOptions.bar,
                          horizontal: true,
                          barHeight: 10,
                        },
                      },
                      tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                          const category = w.config.xaxis.categories[dataPointIndex];
                      
                          // Find the corresponding milestone or task
                          const milestone = projectDetailData?.find(
                            (milestone) => milestone.name === category
                          );
                          const formatDate = (date) =>
                            date
                              ? new Date(date).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                })
                              : "N/A";
                          // Check if the category is a milestone or a task
                          if (milestone) {
                            return `
                              <div style="padding: 10px; font-size: 12px; background: white; border: 1px solid #ccc;">
                                <strong>Milestone:</strong> ${milestone.name} <br />
                              </div>`;
                          } else {
                            // Check if it's a task within a milestone
                            const task = projectDetailData?.flatMap((milestone) =>
                              milestone.tasks
                            ).find((task) => task.name === category);
                      
                            if (task) {
                              const startDate = formatDate(task.start_date);
                              const dueDate = formatDate(task.due_date);
                              return `
                                <div style="padding: 10px; font-size: 12px; background: white; border: 1px solid #ccc;">
                                  <strong>Task:</strong> ${task.name || "Unnamed Task"} <br />
                                  <strong>Duration:</strong> ${startDate} - ${dueDate}
                                </div>`;
                            }
                          }
                      
                          // Default case (if no match is found)
                          return `
                            <div style="padding: 10px; font-size: 12px; background: white; border: 1px solid #ccc;">
                              <strong>Category:</strong> ${category}
                            </div>`;
                        },
                      },                      
                    
                    }}
                    series={[
                      {
                        name: "Milestones & Tasks",
                        data: projectDetailData?.flatMap((milestone) => {
                          const currentDate = new Date().getTime();
                          // Prepare tasks data
                          const milestoneTasks = milestone.tasks.map((task) => {
                            const isOverdue =
                              task.due_date &&
                              new Date(task.due_date).getTime() < currentDate;
                            const color = isOverdue
                              ? statusColorMap["overdue"] // Red for overdue tasks
                              : statusColorMap[task.status] || "#888"; // Default to status color
                              
                            return {
                              x: task.name || "Unnamed Task",
                              y: [
                                task.start_date
                                  ? new Date(task.start_date).getTime()- 41200000
                                  : new Date().getTime() - 43200000,
                                task.due_date
                                  ? new Date(task.due_date).getTime() - 41200000
                                  : new Date().getTime() - 43200000,
                              ],
                              fillColor: color, // Bar color
                            };
                          });

                          // Calculate milestone start and end dates
                          const milestoneStartDate = Math.min(
                            ...milestoneTasks
                              .map((task) => task.y[0])
                              .filter(Boolean)
                          );
                          const milestoneEndDate = Math.max(
                            ...milestoneTasks
                              .map((task) => task.y[1])
                              .filter(Boolean)
                          );

                          const isMilestoneOverdue =
                            milestoneEndDate < currentDate;

                          // Add milestone as the first entry in the series
                          const milestoneData = {
                            x: milestone.name,
                            y: [milestoneStartDate, milestoneEndDate],
                            fillColor: isMilestoneOverdue
                              ? statusColorMap["overdue"] // Red for overdue milestones
                              : "#75DCD6", // Default milestone color
                          };
                          return [milestoneData, ...milestoneTasks]; // Combine milestone and tasks
                        }),
                      },
                    ]}
                    type="rangeBar"
                    height={
                      300 + // Base height
                      (projectDetailData?.length || 0) * 50 + // Height for milestones
                      projectDetailData?.reduce(
                        (sum, milestone) => sum + (milestone.tasks.length || 0),
                        0
                      ) * 30 // Height for tasks
                    }
                    width={isDrawerOpen ? 950 : 1150}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          </CSSTransition>
          </SwitchTransition>
          {/* MileStone Accordian */}
          <Box sx={{ mt: 4, mb: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "700",
                  color: "secondary.main",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1.1rem",
                    maxWidth: "350px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "250px",
                  },
                }}
                variant="h4"
                noWrap
                component="div"
              >
                Milestones
              </Typography>
              <Typography
                onClick={() => handleCreateMilestone()}
                sx={{
                  color: "secondary.main",
                  fontWeight: 650,
                  fontSize: "0.9rem",
                  cursor: "pointer", // Makes it clear that it's clickable
                  display: "flex",
                  alignItems: "center",
                  pr: 1,
                  gap: "5px",
                  "&:hover": {
                    textDecoration: "underline", // Optional hover effect
                  },
                }}
              >
                <AddOutlinedIcon
                  sx={{ fontSize: "1.1rem", fontWeight: "650", mb: 0.4 }}
                />
                Create New Milestone
              </Typography>
            </Box>
            {projectDetail && projectDetail?.milestones?.length > 0 ? (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppableMilestone" type="milestone">
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      // {...provided.dragHandleProps}
                      sx={{ mt: 3, mb: 8 }}
                    >
                      {projectDetailData.map((milestone, index) => (
                        <Draggable
                          key={`milestone-${index}`}
                          draggableId={`milestone-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Accordion
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              isDragging={snapshot.isDragging}
                              sx={{
                                borderRadius: "10px",
                                overflow: "hidden",
                                mt: 2,
                                "&:first-of-type": {
                                  marginTop: 0,
                                  borderRadius: "10px 10px 10px 10px",
                                  mt: 2,
                                },
                                "&:last-of-type": {
                                  marginTop: 0,
                                  borderRadius: "10px 10px 10px 10px",
                                  mt: 2,
                                },
                              }}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon
                                    sx={{ color: "lightSecondary.main" }}
                                  />
                                }
                                sx={{
                                  "& .MuiAccordionSummary-content": {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                  }}
                                >
                                  {/* drag and drop icon */}
                                  <Tooltip arrow title="Drag Milestone">
                                    <IconButton
                                      sx={{
                                        cursor: "grab",
                                        color: "lightSecondary.main",
                                      }}
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorIcon
                                        sx={{
                                          fontSize: "1.1em",
                                          color: "lightSecondary.main",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Typography
                                    sx={{
                                      fontSize: "1.2rem",
                                      fontWeight: "600",
                                      color: "secondary.main",
                                    }}
                                  >
                                    {milestone.name.charAt(0).toUpperCase() + milestone.name.slice(1).toLowerCase()}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "0.8rem",
                                      fontWeight: "500",
                                      color: "lightSecondary.main",
                                    }}
                                  >
                                    ({milestone?.tasks?.length} Tasks)
                                  </Typography>
                                </Box>
                                <Box>
                                  {/* Edit Icon */}
                                  <Tooltip arrow title="Edit Milestone">
                                    <IconButton
                                      onClick={() =>
                                        handleEditMilestone(
                                          milestone,
                                          milestone._id
                                        )
                                      }
                                    >
                                      <ModeEditOutlineIcon
                                        sx={{ color: "lightSecondary.main" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip arrow title="Add Task">
                                    <IconButton
                                      onClick={() =>
                                        handleOpenAddTask(milestone._id)
                                      }
                                    >
                                      <AddCircleIcon
                                        sx={{
                                          fontSize: "1.1em",
                                          color: "lightSecondary.main",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography
                                  sx={{
                                    fontWeight: "400",
                                    fontSize: "0.9em",
                                    color: "#106eed",
                                    lineHeight: "1.5",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: milestone.description,
                                  }}
                                />

                                {/* </Typography> */}
                                {/* Document */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    mt: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "600",
                                        fontSize: "0.9em",
                                        color: "secondary.main",
                                      }}
                                    >
                                      Document :
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    {milestone?.drive_ids.length > 0 ? (
                                      milestone?.drive_ids.map(
                                        (document, index) => {
                                          const fileExtension = document.name
                                            ? document.name
                                                .split(".")
                                                .pop()
                                                .toLowerCase()
                                            : "";
                                          const isJPG = fileExtension === "jpg";
                                          const isJPEG =
                                            fileExtension === "jpeg";
                                          const isPNG = fileExtension === "png";
                                          const isPDF = fileExtension === "pdf";
                                          const isGif = fileExtension === "gif";
                                          const isDocx =
                                            fileExtension === "docx";
                                          const isDoc = fileExtension === "doc";
                                          const isMp4 = fileExtension === "mp4";
                                          const isAvi = fileExtension === "avi";
                                          const isMov = fileExtension === "mov";
                                          const isWmv = fileExtension === "wmv";
                                          const isMp3 = fileExtension === "mp3";
                                          const isWav = fileExtension === "wav";
                                          const isOgg = fileExtension === "ogg";
                                          const xlsx = fileExtension === "xlsx";
                                          const csv = fileExtension === "csv";
                                          return (
                                            <ListItemButton
                                              key={document._id}
                                              sx={{
                                                border: "solid 1px #A2C2F1",
                                                minWidth: "165px",
                                                maxWidth: "165px",
                                                background: "#ECF6FF",
                                                padding: "8px 5px",
                                                borderRadius: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                flexGrow: "inherit",
                                                "@media (max-width:2560px) and (min-width:1200px)":
                                                  {
                                                    minWidth: "170px",
                                                    maxWidth: "170px",
                                                    padding: "8px 5px",
                                                  },
                                                "@media (max-width:1200px) and (min-width:900px)":
                                                  {
                                                    minWidth: "170px",
                                                    maxWidth: "100%",
                                                    padding: "8px 5px",
                                                  },
                                                [theme.breakpoints.down("md")]:
                                                  {
                                                    minWidth: "170px",
                                                    maxWidth: "45%",
                                                    width: "20%",
                                                  },
                                                [theme.breakpoints.down("sm")]:
                                                  {
                                                    width: "20%",
                                                    maxWidth: "30%",
                                                  },
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {/* Avatar with Static Image */}
                                                <ListItemAvatar
                                                  sx={{
                                                    textAlign: "center",
                                                    minWidth: "30px",
                                                    mixBlendMode: "multiply",
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      maxWidth: "26px",
                                                      maxHeight: "16px",
                                                      display: "block",
                                                    }}
                                                    src={
                                                      isPNG
                                                        ? getImages("png.png")
                                                        : isJPG
                                                        ? getImages("jpg.png")
                                                        : isJPEG
                                                        ? getImages("img.png")
                                                        : isPDF
                                                        ? getImages("pdf.png") // Replace with your PDF icon path
                                                        : isGif
                                                        ? getImages("file.png")
                                                        : isDocx
                                                        ? getImages("doc.png")
                                                        : isDoc
                                                        ? getImages("doc.png")
                                                        : isMp4
                                                        ? getImages("mp4.png")
                                                        : isAvi
                                                        ? getImages("file.png")
                                                        : isMov
                                                        ? getImages("file.png")
                                                        : isWav
                                                        ? getImages("file.png")
                                                        : isMp3
                                                        ? getImages("mp3.png")
                                                        : // : isWav
                                                        // ? getImages("wav.png")
                                                        isOgg
                                                        ? getImages("file.png")
                                                        : xlsx
                                                        ? getImages("xls.png")
                                                        : csv
                                                        ? getImages("csv.png")
                                                        : getImages("file.png") // Replace with your default icon path
                                                    }
                                                    alt={document.name || "doc"}
                                                  />
                                                </ListItemAvatar>

                                                {/* Document Name */}
                                                <Typography
                                                  variant="h6"
                                                  noWrap
                                                  sx={{
                                                    minWidth: "100px",
                                                    maxWidth: "100px",
                                                    "@media (max-width:2560px) and (min-width:1200px)":
                                                      {
                                                        minWidth: "100px",
                                                        maxWidth: "100px",
                                                      },
                                                    "@media (max-width:1200px) and (min-width:900px)":
                                                      {
                                                        minWidth: "80px",
                                                        maxWidth: "80px",
                                                      },
                                                    color:
                                                      "lightSecondary.main",
                                                    fontWeight: "600",
                                                    fontSize: "0.7rem",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={document.name}
                                                  >
                                                    {" "}
                                                    {document.name}
                                                  </Tooltip>
                                                </Typography>
                                              </Box>
                                              <div>
                                                <IconButton
                                                  aria-label="more"
                                                  id={`long-button-${index}`}
                                                  aria-controls={
                                                    anchorEl
                                                      ? "long-menu"
                                                      : undefined
                                                  }
                                                  aria-expanded={
                                                    anchorEl
                                                      ? "true"
                                                      : undefined
                                                  }
                                                  aria-haspopup="true"
                                                  onClick={(event) =>
                                                    handleClick(
                                                      event,
                                                      document.s3Url,
                                                      document.name
                                                    )
                                                  }
                                                >
                                                  <MoreVertIcon
                                                    sx={{
                                                      width: "20px",
                                                      height: "15px",
                                                    }}
                                                  />
                                                </IconButton>
                                                <Menu
                                                  id={`long-menu-${index}`}
                                                  MenuListProps={{
                                                    "aria-labelledby": `long-button-${index}`,
                                                  }}
                                                  anchorEl={anchorEl}
                                                  open={Boolean(anchorEl)}
                                                  onClose={handleClose}
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight:
                                                        ITEM_HEIGHT * 1.5, // Adjust max height
                                                      width: "18ch",
                                                      boxShadow:
                                                        "0px 4px 10px rgba(0, 0, 0, 0.25)", // Add shadow
                                                    },
                                                  }}
                                                >
                                                  <MenuItem
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: "600",
                                                      lineHeight: "20px",
                                                      color:
                                                        "lightSecondary.main",
                                                      py: 1,
                                                      px: 1.4,
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "extraLTheme.main",
                                                      },
                                                      "&.active": {
                                                        backgroundColor:
                                                          "primary.main",
                                                        color: "#fff",
                                                        borderRadius: "10px",
                                                      },
                                                    }}
                                                    onClick={() =>
                                                      handleDownload()
                                                    }
                                                  >
                                                    <FileDownloadOutlinedIcon
                                                      sx={{
                                                        mr: 1,
                                                      }}
                                                    />{" "}
                                                    Download
                                                  </MenuItem>
                                                </Menu>
                                              </div>
                                            </ListItemButton>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                </Box>
                                {/* Droppable Task List */}
                                <Droppable
                                  droppableId={`tasks-${index}`}
                                  type="task"
                                >
                                  {(provided) => (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                        mt: 2,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "0.9em",
                                          color: "secondary.main",
                                        }}
                                      >
                                        Tasks :
                                      </Typography>
                                      {milestone?.tasks.length > 0 ? (
                                        milestone?.tasks?.map((task, index) => (
                                          <Draggable
                                            key={`task-${index}`}
                                            draggableId={`task-${index}`}
                                            index={index}
                                            sx={{
                                              top: "0 !important",
                                              left: "0 !important",
                                              bottom: "0 !important",
                                              right: "0 !important",
                                            }}
                                          >
                                            {(provided, snapshot) => (
                                              <Accordion
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                isDragging={snapshot.isDragging}
                                                sx={{
                                                  borderRadius: "10px",
                                                  overflow: "hidden",
                                                  backgroundColor: "#f9f9f9",
                                                  "&:first-of-type": {
                                                    marginTop: 0,
                                                    borderRadius:
                                                      "10px 10px 10px 10px",
                                                    // mt: 2,
                                                  },
                                                  "&:last-of-type": {
                                                    marginTop: 0,
                                                    borderRadius:
                                                      "10px 10px 10px 10px",
                                                    // mt: 2,
                                                  },
                                                }}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <ExpandMoreIcon
                                                      sx={{
                                                        color:
                                                          "lightSecondary.main",
                                                      }}
                                                    />
                                                  }
                                                  sx={{
                                                    "& .MuiAccordionSummary-content":
                                                      {
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                        alignItems: "center",
                                                      },
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      gap: 1,
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    {/* drag and drop icon */}
                                                    {/* <Tooltip
                                                      arrow
                                                      title="Drag Task"
                                                    >
                                                      <IconButton
                                                        sx={{
                                                          cursor: "grab",
                                                          color:
                                                            "lightSecondary.main",
                                                        }}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <DragIndicatorIcon
                                                          sx={{
                                                            fontSize: "1.1em",
                                                            color:
                                                              "lightSecondary.main",
                                                          }}
                                                        />
                                                      </IconButton>
                                                    </Tooltip> */}
                                                    <Typography
                                                      sx={{
                                                        fontWeight: "600",
                                                        color: "secondary.main",
                                                      }}
                                                    >
                                                      {task.name.charAt(0).toUpperCase() +
                                                      task.name.slice(1).toLowerCase()}
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: "0.8rem",
                                                        fontWeight: "500",
                                                        color:
                                                          "lightSecondary.main",
                                                      }}
                                                    >
                                                      {`${formatDate(
                                                        task.start_date
                                                      )} - ${formatDate(
                                                        task.due_date
                                                      )}`}
                                                    </Typography>
                                                    <Chip
                                                      sx={{
                                                        fontWeight: "600",
                                                        borderRadius: "5px",
                                                        backgroundColor:
                                                          "transparent",
                                                        color: "secondary.main",
                                                      }}
                                                      label={
                                                        task.priority.charAt(0).toUpperCase() +
                                                        task.priority.slice(1).toLowerCase()
                                                      }
                                                    />
                                                  </Box>
                                                  <Box>
                                                    {/* Status Dropdown */}
                                                    <Select
                                                      value={task.status}
                                                      disabled
                                                      displayEmpty
                                                      variant="standard"
                                                      disableUnderline
                                                      IconComponent={() => null} // Removes dropdown icon
                                                      sx={{
                                                        fontWeight: 600,
                                                        fontSize: "0.9em",
                                                        "& .MuiInput-input": {
                                                          paddingRight: "5px",
                                                        },
                                                        "& .MuiSelect-select": {
                                                          padding: "2px 6px",
                                                          backgroundColor:
                                                            task.status ===
                                                            "completed"
                                                              ? "#7CDDA3"
                                                              : task.status ===
                                                                "inprogress"
                                                              ? "#75DCD6"
                                                              : "#F7CB73",
                                                          borderRadius: "4px",
                                                          paddingRight:
                                                            "5px !important",
                                                        },
                                                      }}
                                                    >
                                                      <MenuItem
                                                        disabled
                                                        value={task.status}
                                                      >
                                                        {task.status ===
                                                        "inprogress"
                                                          ? "In-Progress"
                                                          : task.status.charAt(0).toUpperCase() +
                                                            task.status.slice(1).toLowerCase()}
                                                      </MenuItem>
                                                    </Select>

                                                    <Tooltip
                                                      arrow
                                                      title="Edit task"
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          handleOpenEditTask(
                                                            task,
                                                            task._id,
                                                            milestone._id
                                                          )
                                                        }
                                                      >
                                                        <ModeEditOutlineIcon
                                                          sx={{
                                                            color:
                                                              "lightSecondary.main",
                                                          }}
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Box>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      gap: "5px",
                                                      alignItems: "flex-start", // Align top of Detail and text
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        fontWeight: "400",
                                                        fontSize: "0.9em",
                                                        color: "#106eed",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          task.description,
                                                      }}
                                                    />
                                                  </Box>
                                                  {/* Document */}
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      gap: "5px",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        fontWeight: "600",
                                                        fontSize: "0.9em",
                                                        color: "secondary.main",
                                                      }}
                                                    >
                                                      Document :
                                                    </Typography>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        gap: 1,
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {task?.drive_ids.length >
                                                      0 ? (
                                                        task?.drive_ids.map(
                                                          (document, index) => {
                                                            const fileExtension =
                                                              document.name
                                                                ? document.name
                                                                    .split(".")
                                                                    .pop()
                                                                    .toLowerCase()
                                                                : "";
                                                            const isJPG =
                                                              fileExtension ===
                                                              "jpg";
                                                            const isJPEG =
                                                              fileExtension ===
                                                              "jpeg";
                                                            const isPNG =
                                                              fileExtension ===
                                                              "png";
                                                            const isPDF =
                                                              fileExtension ===
                                                              "pdf";
                                                            const isGif =
                                                              fileExtension ===
                                                              "gif";
                                                            const isDocx =
                                                              fileExtension ===
                                                              "docx";
                                                            const isDoc =
                                                              fileExtension ===
                                                              "doc";
                                                            const isMp4 =
                                                              fileExtension ===
                                                              "mp4";
                                                            const isAvi =
                                                              fileExtension ===
                                                              "avi";
                                                            const isMov =
                                                              fileExtension ===
                                                              "mov";
                                                            const isWmv =
                                                              fileExtension ===
                                                              "wmv";
                                                            const isMp3 =
                                                              fileExtension ===
                                                              "mp3";
                                                            const isWav =
                                                              fileExtension ===
                                                              "wav";
                                                            const isOgg =
                                                              fileExtension ===
                                                              "ogg";
                                                            const xlsx =
                                                              fileExtension ===
                                                              "xlsx";
                                                            const csv =
                                                              fileExtension ===
                                                              "csv";
                                                            return (
                                                              <ListItemButton
                                                                key={
                                                                  document._id
                                                                }
                                                                sx={{
                                                                  border:
                                                                    "solid 1px #A2C2F1",
                                                                  minWidth:
                                                                    "165px",
                                                                  maxWidth:
                                                                    "165px",
                                                                  background:
                                                                    "#ECF6FF",
                                                                  padding:
                                                                    "8px 5px",
                                                                  borderRadius:
                                                                    "10px",
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  color:
                                                                    "lightSecondary.main",
                                                                  fontWeight:
                                                                    "600",
                                                                  fontSize:
                                                                    "1rem",
                                                                  flexGrow:
                                                                    "inherit",
                                                                  "@media (max-width:2560px) and (min-width:1200px)":
                                                                    {
                                                                      minWidth:
                                                                        "170px",
                                                                      maxWidth:
                                                                        "170px",
                                                                      padding:
                                                                        "8px 5px",
                                                                    },
                                                                  "@media (max-width:1200px) and (min-width:900px)":
                                                                    {
                                                                      minWidth:
                                                                        "170px",
                                                                      maxWidth:
                                                                        "100%",
                                                                      padding:
                                                                        "8px 5px",
                                                                    },
                                                                  [theme.breakpoints.down(
                                                                    "md"
                                                                  )]: {
                                                                    minWidth:
                                                                      "170px",
                                                                    maxWidth:
                                                                      "45%",
                                                                    width:
                                                                      "20%",
                                                                  },
                                                                  [theme.breakpoints.down(
                                                                    "sm"
                                                                  )]: {
                                                                    width:
                                                                      "20%",
                                                                    maxWidth:
                                                                      "30%",
                                                                  },
                                                                }}
                                                              >
                                                                <Box
                                                                  sx={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  {/* Avatar with Static Image */}
                                                                  <ListItemAvatar
                                                                    sx={{
                                                                      textAlign:
                                                                        "center",
                                                                      minWidth:
                                                                        "30px",
                                                                      mixBlendMode:
                                                                        "multiply",
                                                                    }}
                                                                  >
                                                                    <img
                                                                      style={{
                                                                        maxWidth:
                                                                          "26px",
                                                                        maxHeight:
                                                                          "16px",
                                                                        display:
                                                                          "block",
                                                                      }}
                                                                      src={
                                                                        isPNG
                                                                          ? getImages(
                                                                              "png.png"
                                                                            )
                                                                          : isJPG
                                                                          ? getImages(
                                                                              "jpg.png"
                                                                            )
                                                                          : isJPEG
                                                                          ? getImages(
                                                                              "img.png"
                                                                            )
                                                                          : isPDF
                                                                          ? getImages(
                                                                              "pdf.png"
                                                                            ) // Replace with your PDF icon path
                                                                          : isGif
                                                                          ? getImages(
                                                                              "file.png"
                                                                            )
                                                                          : isDocx
                                                                          ? getImages(
                                                                              "doc.png"
                                                                            )
                                                                          : isDoc
                                                                          ? getImages(
                                                                              "doc.png"
                                                                            )
                                                                          : isMp4
                                                                          ? getImages(
                                                                              "mp4.png"
                                                                            )
                                                                          : isAvi
                                                                          ? getImages(
                                                                              "file.png"
                                                                            )
                                                                          : isMov
                                                                          ? getImages(
                                                                              "file.png"
                                                                            )
                                                                          : isWav
                                                                          ? getImages(
                                                                              "file.png"
                                                                            )
                                                                          : isMp3
                                                                          ? getImages(
                                                                              "mp3.png"
                                                                            )
                                                                          : // : isWav
                                                                          // ? getImages("wav.png")
                                                                          isOgg
                                                                          ? getImages(
                                                                              "file.png"
                                                                            )
                                                                          : xlsx
                                                                          ? getImages(
                                                                              "xls.png"
                                                                            )
                                                                          : csv
                                                                          ? getImages(
                                                                              "csv.png"
                                                                            )
                                                                          : getImages(
                                                                              "file.png"
                                                                            ) // Replace with your default icon path
                                                                      }
                                                                      alt={
                                                                        document.name ||
                                                                        "doc"
                                                                      }
                                                                    />
                                                                  </ListItemAvatar>

                                                                  {/* Document Name */}
                                                                  <Typography
                                                                    variant="h6"
                                                                    noWrap
                                                                    sx={{
                                                                      minWidth:
                                                                        "100px",
                                                                      maxWidth:
                                                                        "100px",
                                                                      "@media (max-width:2560px) and (min-width:1200px)":
                                                                        {
                                                                          minWidth:
                                                                            "100px",
                                                                          maxWidth:
                                                                            "100px",
                                                                        },
                                                                      "@media (max-width:1200px) and (min-width:900px)":
                                                                        {
                                                                          minWidth:
                                                                            "80px",
                                                                          maxWidth:
                                                                            "80px",
                                                                        },
                                                                      color:
                                                                        "lightSecondary.main",
                                                                      fontWeight:
                                                                        "600",
                                                                      fontSize:
                                                                        "0.7rem",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        document.name
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      {
                                                                        document.name
                                                                      }
                                                                    </Tooltip>
                                                                  </Typography>
                                                                </Box>
                                                                <div>
                                                                  <IconButton
                                                                    aria-label="more"
                                                                    id={`long-button-${index}`}
                                                                    aria-controls={
                                                                      anchorEl
                                                                        ? "long-menu"
                                                                        : undefined
                                                                    }
                                                                    aria-expanded={
                                                                      anchorEl
                                                                        ? "true"
                                                                        : undefined
                                                                    }
                                                                    aria-haspopup="true"
                                                                    onClick={(
                                                                      event
                                                                    ) =>
                                                                      handleClick(
                                                                        event,
                                                                        document.s3Url,
                                                                        document.name
                                                                      )
                                                                    }
                                                                  >
                                                                    <MoreVertIcon
                                                                      sx={{
                                                                        width:
                                                                          "20px",
                                                                        height:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </IconButton>
                                                                  <Menu
                                                                    id={`long-menu-${index}`}
                                                                    MenuListProps={{
                                                                      "aria-labelledby": `long-button-${index}`,
                                                                    }}
                                                                    anchorEl={
                                                                      anchorEl
                                                                    }
                                                                    open={Boolean(
                                                                      anchorEl
                                                                    )}
                                                                    onClose={
                                                                      handleClose
                                                                    }
                                                                    PaperProps={{
                                                                      style: {
                                                                        maxHeight:
                                                                          ITEM_HEIGHT *
                                                                          1.5, // Adjust max height
                                                                        width:
                                                                          "18ch",
                                                                        boxShadow:
                                                                          "0px 4px 10px rgba(0, 0, 0, 0.25)", // Add shadow
                                                                      },
                                                                    }}
                                                                  >
                                                                    <MenuItem
                                                                      sx={{
                                                                        fontSize:
                                                                          "0.9rem",
                                                                        fontWeight:
                                                                          "600",
                                                                        lineHeight:
                                                                          "20px",
                                                                        color:
                                                                          "lightSecondary.main",
                                                                        py: 1,
                                                                        px: 1.4,
                                                                        "&:hover":
                                                                          {
                                                                            backgroundColor:
                                                                              "extraLTheme.main",
                                                                          },
                                                                        "&.active":
                                                                          {
                                                                            backgroundColor:
                                                                              "primary.main",
                                                                            color:
                                                                              "#fff",
                                                                            borderRadius:
                                                                              "10px",
                                                                          },
                                                                      }}
                                                                      onClick={() =>
                                                                        handleDownload()
                                                                      }
                                                                    >
                                                                      <FileDownloadOutlinedIcon
                                                                        sx={{
                                                                          mr: 1,
                                                                        }}
                                                                      />{" "}
                                                                      Download
                                                                    </MenuItem>
                                                                  </Menu>
                                                                </div>
                                                              </ListItemButton>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </Box>

                                                    {provided.placeholder}
                                                  </Box>
                                                </AccordionDetails>
                                              </Accordion>
                                            )}
                                          </Draggable>
                                        ))
                                      ) : (
                                        <></>
                                      )}
                                      {provided.placeholder}
                                    </Box>
                                  )}
                                </Droppable>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            ) : projectDetailLoading ? (
              <Grid container spacing={2}>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Grid xs={12}>
                    <MilestoneSkeleton key={index} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ mt: 2 }}>
                <NoMilestoneScreen />
              </Box>
            )}
          </Box>
        </>
      )}
      {/* Add Task */}
      <Modals
        open={openAddTaskModal}
        handleClose={handleCloseAddTask}
        modalWidth={650}
      >
        <AddTask
          handleClose={handleCloseAddTask}
          showToastMessage={showToastMessage}
        />
      </Modals>
      {/* Edit Task */}
      <Modals
        open={openEditTaskModal}
        handleClose={handleCloseEditTask}
        modalWidth={650}
      >
        <EditTask
          showToastMessage={showToastMessage}
          handleClose={handleCloseEditTask}
        />
      </Modals>
    </>
  );
};

export default ProjectDetail;
