
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";


const baseUrl =process.env.REACT_APP_base_URL;

const editMilestone = createApi({
    reducerPath: 'editMilestoneApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        editMilestone: builder.mutation({
            query: ({ projectId , formData}) => ({
                url:`projects/editMilestone/${projectId}`,
                method: 'POST',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useEditMilestoneMutation } = editMilestone;
export default editMilestone;
