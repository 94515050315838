import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  Select,
  FormControl,
  TextField,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreateMilestoneState,
  setMilestoneDescription,
  setMilestoneStatus,
  setMilestoneTitle,
} from "../../redux/features/MyProjects/createMilestoneSlice";
import { useCreateMilestoneMutation } from "../../redux/api/MyProjects/createMilestone";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { setRefetchProjectDetail } from "../../redux/features/refetch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddMileStone = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //  States
  const { milestoneTitle, milestoneDescription, milestoneStatus } = useSelector(
    (state) => state.createMilestone
  );
  const { projectId } = useSelector((state) => state.projectSlice);

  // validation state
  const [errors, setErrors] = useState({
    milestoneTitle: false,
    milestoneDescription: false,
  });

  // api's

  // create milestone
  const [
    createMilestone,
    { isLoading: milestoneCreateLoading, isError: milestoneCreateError },
  ] = useCreateMilestoneMutation();

  // functions
  const handleMilestoneTitle = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneTitle: false,
      }));
      return;
    }
    dispatch(setMilestoneTitle(inputValue)); // Assuming setMilestoneTitle takes a value
    if (inputValue !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, milestoneTitle: false }));
    }
  };

  const handleMilestoneDescription = (inputValue) => {
    if (inputValue.length > 350) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneDescription: true,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneDescription: false,
      }));
    }
    dispatch(setMilestoneDescription(inputValue));
  };

  const handleMilestoneStatus = (event) => {
    const inputValue = event.target.value;
    dispatch(setMilestoneStatus(inputValue));
  };

  const createNewMilestone = async () => {
    let hasError = false;
    const newErrors = { milestoneTitle: false, milestoneDescription: false };

    // Validation
    if (milestoneTitle === "") {
      newErrors.milestoneTitle = true;
      hasError = true;
    }
    if (milestoneDescription.length > 250) {
      newErrors.milestoneDescription = true;
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }

    // Build the milestoneData array
    const milestoneData = [];

    milestoneData.push({
      name: milestoneTitle,
      description: milestoneDescription,
    });

    // API call
    try {
      const response = await createMilestone({
        projectId: projectId, // Replace with actual projectId
        milestones: milestoneData,
      }).unwrap();
      const successMessage =
        response?.message || "Milestone created successfully";
      if (response.statusCode === 200) {
        showToastMessage(successMessage, "success");
      }
      if (response?.statusCode === 500) {
        showToastMessage("Server error occurred", "error");
      }
      dispatch(setRefetchProjectDetail(true));
    } catch (milestoneCreateError) {
      const errorMessage =
        milestoneCreateError?.message || "Failed to create milestone";
      showToastMessage(errorMessage, "error");
    } finally {
      dispatch(resetCreateMilestoneState());
      navigate("/project-detail");
    }
  };

  const handleGoBack = () => {
    navigate("/project-detail");
  };

  useEffect(() => {
    if (milestoneCreateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [milestoneCreateLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleGoBack}
            sx={{
              marginRight: "5px",
              display: "inline-flex",
              p: 0,
              cursor: "pointer",
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "32px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: "1.4rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            Create Milestone
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        mt={1}
        columnSpacing={2}
        sx={{
          // p:2,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("xl")]: {
            // p: 10,
            mt: 8,
          },
        }}
      >
        <Grid item xs={12} md={11} lg={11}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              mb: 3,
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                p: 4,
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
              }}
              style={{
                paddingBottom: "0",
              }}
            >
              <Box
                component="form"
                sx={{
                  mt: 0,
                  mb: 0,
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={5}
                  sx={{
                    mb: 1,
                  }}
                >
                  {/* Parent Grid for Solution Benefits and Key Features */}
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={5}
                    item
                    xs={12}
                    spacing={2}
                  >
                    {/* Milestone Column */}
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={5}
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Milestone Title*
                        </Typography>
                        <TextField
                          hiddenLabel
                          variant="filled"
                          value={milestoneTitle}
                          onChange={handleMilestoneTitle}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              pt: 1.5,
                              pb: 1.5,
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                            "& .MuiInputBase-root.Mui-focused .MuiIconButton-root":
                              {
                                backgroundColor: "transparent !important", // Ensure it does not inherit focus styles
                              },
                          }}
                        />
                        {errors.milestoneTitle && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                      {/* Description  */}
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Description
                        </Typography>
                        {/* <TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          variant="filled"
                          value={milestoneDescription}
                          onChange={handleMilestoneDescription}
                          multiline
                          rows={3}
                          maxRows={3}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiFilledInput-root": {
                              backgroundColor: "extraLTheme.main", // Set main background color
                              borderRadius: "4px", // Ensure corners are aligned
                              "&:hover": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "&::before, &::after": {
                                borderBottom: "none", // Remove underline completely
                              },
                              "&.Mui-focused": {
                                backgroundColor: "extraLTheme.main", // Ensure consistency when focused
                                border: "1px solid transparent", // Retain border
                              },
                            },
                            "& .MuiFilledInput-underline:before": {
                              borderBottom: "none", // Remove default underline
                            },
                            "& .MuiFilledInput-underline:after": {
                              borderBottom: "none", // Remove default underline
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "600",
                              // pt: 1.5,
                              pb: 1.5,
                              backgroundColor: "extraLTheme.main",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#9C9C9C",
                                borderRadius: "4px",
                              },
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                                backgroundColor: "extraLTheme.main",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                          }}
                        /> */}
                        <Box
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            border: "solid 1px #DBEBF8",
                            backgroundColor: "extraLTheme.main",
                            marginBottom: 10
                          }}
                          sx={{
                           "& .ql-editor": {
                            fontSize: "0.9rem",
                            color: "lightSecondary.main",
                            fontWeight: "500",
                            width: "100%",
                            maxWidth:1000
                           },
                           "& .ql-toolbar": {
                            width: "100%",
                            // borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",

                           },
                           "& .ql-container": {
                            width: "100%",
                            // borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            maxWidth:'100%'
                           },
                           
                          }}
                        >
                          <ReactQuill
                            value={milestoneDescription}
                            onChange={handleMilestoneDescription}
                            placeholder={"Write something here..."}
                            theme="snow"
                            style={{
                              height: "150px",
                              backgroundColor: "extraLTheme.main",
                              fontSize: "1.9rem",
                              fontWeight: "600",
                              color: "lightSecondary.main",
                              borderRadius: "4px",
                              width:'100%',
                              maxWidth:'100%'
                            }}
                            
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }], 
                              ],
                              
                            }}
                            formats={[
                              "bold",
                              "italic",
                              "underline",
                              "list",
                              "bullet",
                            ]}
                          />
                          {errors.milestoneDescription && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            Description cannot exceed 250 characters
                          </Typography>
                        )}
                        </Box>
                        
                      </Grid>
                      {/* Status */}
                      {/* <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Status*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={milestoneStatus}
                            onChange={handleMilestoneStatus}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value={"Todo"}>To do</MenuItem>
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={milestoneCreateLoading}
                      sx={{
                        textTransform: "inherit",
                        p: "10px 20px",
                        boxShadow: "none",
                        fontWeight: "600",
                        width: "200px",
                      }}
                      onClick={createNewMilestone}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddMileStone;
