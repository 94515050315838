import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";
const baseUrl = process.env.REACT_APP_base_URL;
export const getProjectDetail = createApi({
    reducerPath: 'getProjectDetail',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
          const token = store.getState().user.token;
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
          return headers;
      },
    }),
    endpoints:(builder)=>({
        fetchProjectDetail: builder.query({
        query:({projectId})=>({
            url:`projects/detail/${projectId}`,
            method: 'GET',
        }),
        retry: (failureCount, error) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
          return response;
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch project detail error:', error);
          // Handle error cases as per your application's requirements
        },
        }),
    })
})

export const {useFetchProjectDetailQuery} =  getProjectDetail;
export default  getProjectDetail;