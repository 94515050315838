import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";
const baseUrl = process.env.REACT_APP_base_URL;
export const getProjectList = createApi({
    reducerPath: 'getProjectList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
          // const token = useSelector((state)=> state.user.token)
          const token = store.getState().user.token;
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
          return headers;
      },
    }),
    endpoints:(builder)=>({
        fetchProjectList: builder.query({
        query:(searchValue)=>({
            url:`projects?search=${searchValue}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
          return response
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch project list error:', error);
          // Handle error cases as per your application's requirements
        },
        }),
    })
})

export const {useFetchProjectListQuery} =  getProjectList;
export default  getProjectList;