import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Skeleton,
} from "@mui/material";

const PlanCardSkull = ({ count = 3 }) => {
  // Generate skeletons dynamically based on the count
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              textAlign: "center",
              backgroundColor: "#ecf6ff",
              border: "1px solid #001a8c",
              borderRadius: "10px",
              transition: "all 0.5s ease-in-out 0s",
              "&:hover": {
                background: "#ffffff",
                boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
              },
            }}
          >
            <CardContent>
              <Skeleton
                variant="text"
                width="20%"
                height={20}
                sx={{ margin: "0 auto", mb: 1, bgcolor: "rgba(0, 0, 0, 0.05)" }}
              />
              <Skeleton
                variant="text"
                width="40%"
                height={20}
                sx={{ margin: "0 auto", mb: 1, bgcolor: "rgba(0, 0, 0, 0.05)" }}
              />
              <Skeleton
                variant="rectangular"
                width="40%"
                height={30}
                sx={{ margin: "0 auto", mb: 1, bgcolor: "rgba(0, 0, 0, 0.05)" }}
              />
              {/* <Skeleton variant="rectangular" width="80%" height={40} sx={{ margin: "0 auto", mb: 2 }} /> */}
              <Box sx={{ textAlign: "left", margin: "0 auto", maxWidth: 600 }}>
                {/* Main Heading Skeleton */}
                <Skeleton
                  width="40%"
                  height={30}
                  sx={{ mb: 2, bgcolor: "rgba(0, 0, 0, 0.05)" }}
                />

                {/* Subheadings Skeleton as a List */}
                <Box
                  sx={{ textAlign: "left", margin: "0 auto", maxWidth: 600 }}
                >
                  {/* Main Heading Skeleton */}
                  <Skeleton
                    width="40%"
                    height={30}
                    sx={{ mb: 2, bgcolor: "rgba(0, 0, 0, 0.05)" }}
                  />

                  {/* Subheadings Skeleton with Skull Dots */}
                  {Array.from({ length: 8 }).map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 0.5, // Space between items
                      }}
                    >
                      {/* Rounded Skeleton (Skull) */}
                      <Skeleton
                        variant="circular"
                        width={12}
                        height={12}
                        sx={{
                          flexShrink: 0,
                          mr: 1.5,
                          bgcolor: "rgba(0, 0, 0, 0.05)",
                        }}
                      />

                      {/* Subheading Skeleton */}
                      <Skeleton
                        width="70%"
                        height={20}
                        sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default PlanCardSkull;
