
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";


const baseUrl =process.env.REACT_APP_base_URL;

const createTask = createApi({
    reducerPath: 'createTaskApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createTask: builder.mutation({
            query: ({ projectId ,taskDetail , milestoneId}) => ({
                url:`projects/tasks/${projectId}`,
                method: 'POST',
                body: {
                    tasks: taskDetail,
                    milestone_id: milestoneId
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useCreateTaskMutation } = createTask;
export default createTask;
