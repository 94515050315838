import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  Typography,
  styled,
  InputBase,
  Stack,
  IconButton,
  Skeleton,
  ListItemText,
  Avatar,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../const";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { useFetchRequirementBoardDetailsQuery } from "../../redux/api/Requirement Boards/getRequirementBoardDetail";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getSolutionId,
  getSolutionName,
} from "../../redux/features/driveSlice";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useFetchInboxBusinessUserListQuery } from "../../redux/api/SolutionProvider/Inbox/getBusinessUserList";
import { getGroupChatId, getShareListBusinessId, getShareListSolutionId } from "../../redux/features/BusinessUser/driveShareSlice";

const ShareSelectedBoardModal = ({ shareChatOpen, showToastMessage }) => {
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const selectedBoardId = useSelector(
    (state) => state.driveShareSlice.selectedBoardId
  );
  const selectedSolutionId = useSelector(
    (state) => state.driveShareSlice.selectedSolutionId
  );
  const boardName = useSelector((state) => state.drive.boardName);
  const folderName = useSelector((state) => state.drive.folderName);
  const DocumentName = useSelector((state) => state.drive.fileName);
  const dispatch = useDispatch();
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  // const status = "Selected"

  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const {
    data: solutionList,
    error: solutionListError,
    isLoading: boardDetailLoading,
    refetch,
  } = useFetchInboxSelectedSolutionListQuery({
    userId: userId,
    selectedBoardId: selectedBoardId,
    searchParams: searchValue,
  });
 console.log(solutionList , "solutionList")
  const {
    data: businessUserList,
    error: businessUserListError,
    isLoading: businessUserListLoading,
    refetch: refetchBusinessUserList,
  } = useFetchInboxBusinessUserListQuery({
    userId: userId,
    solutionId: selectedSolutionId,
  });

  console.log(businessUserList , "businessUserList")

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleSolutionSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        showToastMessage("Please enter a search term", "error");
        return;
      }

      setSearchValue(inputValue);
      dispatch(showLoader());

      try {
        // Await refetch and handle search results
        await refetch();
        if (solutionListError) {
          const errorMessage =
            solutionList?.message || "Failed to load search result";
          showToastMessage(errorMessage, "error");
        }
      } catch (solutionListError) {
        // Handle errors if needed
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  const handleSolutionSelection = (solutionId, solutionName , groupChatId) => {
    localStorage.setItem("selectedSolutionId", solutionId);
    dispatch(getShareListSolutionId(solutionId));
    dispatch(getSolutionName(solutionName));
    dispatch(getGroupChatId(groupChatId));
  };

  const handleBusinessUserSelection = (businessId)=>{
    dispatch(getShareListBusinessId(businessId))
    dispatch(getGroupChatId(businessId))
  }
  //   Loader
  useEffect(() => {
    if (boardDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardDetailLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.3rem",
            color: "secondary.main",
            fontWeight: "700",
            maxWidth: "100%", // Set maximum width to the width of the modal
            whiteSpace: "nowrap", // Prevents text from wrapping onto a new line
            overflow: "hidden", // Hides any overflowed text
            textOverflow: "ellipsis", // Adds "..." at the end of the truncated text
          }}
        >
          Share “{folderName || DocumentName}”
        </Typography>
      </Box>
      <Search>
        <StyledInputBase
          placeholder="Search Solutions"
          inputProps={{ "aria-label": "search" }}
          value={searchParams}
          onChange={handleInputChange}
          onKeyDown={handleSolutionSearch}
          autoFocus
        />
        {searchParams ? (
          <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
          >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
          </IconButton>
        ) : (
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
          </SearchIconWrapper>
        )}
      </Search>
      {
        userType == "solution_provider" ?
        (
          <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            fontSize: "1.1rem",
            fontWeight: "700",
            color: "secondary.main",
            marginBottom: "0rem",
            mt:2
          }}
        >
          Select Solution {`>`} Business User
        </Typography>
        ):
        (
          <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            fontSize: "1.1rem",
            fontWeight: "700",
            color: "secondary.main",
            marginBottom: "0rem",
            mt:2
          }}
        >
          Select Board {`>`} Solution
        </Typography>
        )
      }


      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          mb: 3,
          "& .MuiListItemButton-root": {
            py: 0.5,
          },
          overflowY: "auto",
          maxHeight: "280px",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
        }}
      >
        {
        userType == "solution_provider" ?
        (
          businessUserList || businessUserList?.length > 0 ? 
          (
            businessUserList?.map((businessUser, index) => (
              <ListItemButton
              key={businessUser._id}
                onClick={() => {
                  shareChatOpen();
                  handleBusinessUserSelection(
                    businessUser._id,
                    // businessUser?.userProfile?.business_name
                  );
                }}
                sx={{
                  minHeight: "50px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  marginTop: "15px",
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "inherit",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: "inline-block",
                      marginRight: "7px",
                    }}
                  >
                     {businessUser?.userProfile?.thumbnail_business_logo ? (
                    <Avatar
                      src={businessUser?.userProfile?.thumbnail_business_logo}
                      alt={`${businessUser?.userProfile?.business_name || ""} logo`}
                      sx={{
                        // color: "success.main",
                        bgcolor: "success.lighter",
                        width: "45px",
                        height: "45px",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: "#fff",
                        bgcolor: "lightSecondary.main",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      {businessUser?.userProfile?.business_name
                        ? businessUser.userProfile?.business_name
                            .slice(0, 2)
                            .toUpperCase()
                        : ""}
                    </Avatar>
                  )}
                  </ListItemAvatar>
                  <Tooltip title={businessUser.userProfile?.business_name}>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontSize: "1.1rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      lineHeight: "20px",
                      maxWidth: "260px",
                    }}
                  >
                    {businessUser.userProfile?.business_name}
                  </Typography>
                  </Tooltip>
                </Stack>
                <ArrowForwardIosOutlinedIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "15px",
                  }}
                />
              </ListItemButton>
            ))
          ) : 
          
          businessUserListLoading ? 
            (
            // Show Skeletons while loading
            Array.from({ length: 3 }).map((_, index) => (
              <ListItemButton
                key={index}
                disabled
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5", // Skeleton background color
                  mb: 1,
                  //   padding: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemAvatar
                  sx={{
                    width: "56px",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={56}
                    height={36}
                    sx={{ bgcolor: "#e0e0e0" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    pl: "10px",
                    width: "60%",
                  }}
                >
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.1rem",
                      width: "80%",
                      height: 22,
                      bgcolor: "#e0e0e0",
                    }}
                  />
                </ListItemText>
                <IconButton
                  aria-label="arrow"
                  disabled
                  sx={{
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ bgcolor: "#e0e0e0" }}
                  />
                </IconButton>
              </ListItemButton>
            ))
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="calc(100% - 164px)"
              textAlign="center"
              // padding={3}
              sx={{
                mt: 2,
              }}
            >
              <img
                src={getImages("empty_bsi_co_lab.png")} // Replace with your image path
                // alt="No Data"
                style={{
                  width: "150px",
                  height: "130px",
                  // marginBottom: "20px",
                  mixBlendMode: "multiply",
                }} // Adjust the size as needed
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: "0.9rem",
                  color: "secondary.main",
                  fontWeight: 600,
                }}
                gutterBottom
              >
                No solution added yet
              </Typography>
            </Box>
          )
       
  )
        :
        (
          solutionList?.solutions?.solutions ||
          solutionList?.solutions?.solutions.length > 0 ? 
          (
            solutionList?.solutions?.solutions.map((solution, index) => (
              <ListItemButton
                key={solution.solution_id._id}
                onClick={() => {
                  shareChatOpen();
                  handleSolutionSelection(
                    solution.solution_id._id,
                    solution.solution_id.solution_name,
                    solution._id
                  );
                }}
                sx={{
                  minHeight: "50px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  marginTop: "5px",
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "inherit",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: "inline-block",
                      marginRight: "7px",
                    }}
                  >
                    <img
                      style={{ maxWidth: "54px", display: "block" }}
                      src={`${logoBaseUrl}${solution.solution_id.logo}`}
                      alt={`${solution.solution_id.solution_name} logo`}
                    />
                  </ListItemAvatar>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontSize: "1.1rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      lineHeight: "20px",
                      maxWidth: "260px",
                    }}
                  >
                    {solution.solution_id.solution_name}
                  </Typography>
                </Stack>
                <ArrowForwardIosOutlinedIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "15px",
                  }}
                />
              </ListItemButton>
            ))
          ) : boardDetailLoading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <ListItemButton
                key={index}
                disabled
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5", // Skeleton background color
                  mb: 1,
                  //   padding: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemAvatar
                  sx={{
                    width: "56px",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={56}
                    height={36}
                    sx={{ bgcolor: "#e0e0e0" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    pl: "10px",
                    width: "60%",
                  }}
                >
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.1rem",
                      width: "80%",
                      height: 22,
                      bgcolor: "#e0e0e0",
                    }}
                  />
                </ListItemText>
                <IconButton
                  aria-label="arrow"
                  disabled
                  sx={{
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ bgcolor: "#e0e0e0" }}
                  />
                </IconButton>
              </ListItemButton>
            ))
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="calc(100% - 164px)"
              textAlign="center"
              // padding={3}
              sx={{
                mt: 2,
              }}
            >
              <img
                src={getImages("empty_bsi_co_lab.png")} // Replace with your image path
                // alt="No Data"
                style={{
                  width: "150px",
                  height: "130px",
                  // marginBottom: "20px",
                  mixBlendMode: "multiply",
                }} // Adjust the size as needed
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: "0.9rem",
                  color: "secondary.main",
                  fontWeight: 600,
                }}
                gutterBottom
              >
                No solution added yet
              </Typography>
            </Box>
          )
        )
        
        }
      </List>
    </>
  );
};

export default ShareSelectedBoardModal;
