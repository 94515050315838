import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
export const getUser = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // const token = useSelector((state)=> state.user.token)
      const token = store.getState().user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUserDetails: builder.query({
      query: () => ({
        url: `user/token`,
        method: "GET",
      }),
      transformResponse: (response) => {
        const savedData = response.data;
   // Helper function to get the latest active transaction
   const getRelevantTransaction = (transactions) => {
    if (!transactions || transactions.length === 0) return null;

    // Example: Find the most recent active transaction
    return transactions
      .filter((transaction) => transaction.status === "active")
      .sort((a, b) => new Date(b.expireDate) - new Date(a.expireDate))[0] || null;
  };

  // Extract the relevant transaction
  const relevantTransaction = getRelevantTransaction(
    savedData?.subscription?.transactions
  );

  // Define the subscription object based on the available data
  const subscription = relevantTransaction
    ? {
        ...relevantTransaction,
      }
    : savedData?.subscription
    ? {
        subscriptionPlanId: savedData.subscription._id || null,
        planType: savedData.subscription.plan_type || null,
        planName: savedData.subscription.plan_name || null,
        planSlug: savedData.subscription.plan_slug || null,
        planDescription: savedData.subscription.plan_description || null,
        planValues: Array.isArray(savedData.subscription.plan_values)
          ? savedData.subscription.plan_values.map((item) => ({
              id: item._id,
              key: item.key,
              name: item.name, 
              value: item.value,
              type: item.type,
            }))
          : [],
        isTrialAvailable: savedData.subscription.is_trial_available || null,
        isActive: savedData.subscription.is_active || null,
        trialPeriodDays: savedData.subscription.trial_period_days || null,
      }
    : null;

  // Construct the final transformed response
        return {
          focusIndustry: savedData.focus_industry || [],
          id: savedData._id,
          salutation: savedData.salutation,
          firstName: savedData.first_name,
          status: savedData.status,
          lastName: savedData.last_name,
          email: savedData.email,
          countryId: savedData.country_id._id,
          countryName: savedData.country_id?.name,
          phoneCode: savedData.phone_code,
          countryShortCode: savedData.country_short_code,
          phoneNumber: savedData.phone_number,
          isVerified: savedData.isVerified,
          updatedAt: savedData.updated_at,
          createdAt: savedData.created_at,
          userType: savedData.user_type,
          logo: savedData.avatar ? savedData.avatar : "",
          thumbnailAvatar: savedData?.thumbnailAvatar,
          subscription, // Include only the relevant subscription object
          // unPaid: {
          //   subscriptionPlanId: savedData?.subscription?._id || null,
          //   planType: savedData?.subscription?.plan_type  || null ,
          //   planName: savedData?.subscription?.plan_name || null,
          //   planSlug: savedData?.subscription?.plan_slug || null,
          //   planDescription: savedData?.subscription?.plan_description || null,
          //   planValues: Array.isArray(savedData?.subscription?.plan_values)
          //   ? savedData.subscription.plan_values.map((item) => ({
          //       id: item._id,
          //       key: item.key,
          //       name: item.name,
          //       value: item.value,
          //       type: item.type,
          //     }))
          //   : [],
          //   isTrialAvailable: savedData?.subscription?.is_trial_available || null,
          //   isActive: savedData?.subscription?.is_active || null,
          //   trialPeriodDays: savedData?.subscription?.trial_period_days || null,
          // },
          // Subscription data extraction
          // paid: {
          //   ...relevantTransaction,
          // },
          // paid: {
          //   subscriptionId: savedData?.subscription?._id || null,
          //   isTrialComplete: savedData?.subscription?.is_trial_complete || null,
          //   trialStatus: savedData?.subscription?.trial || null,
          //   trialDays: savedData?.subscription?.trial_days || null,
          //   transactions:
          //     savedData?.subscription?.transactions?.map((transaction) => ({
          //       transactionId: transaction?.transaction_id || null,
          //       status: transaction?.status || null,
          //       startDate: transaction?.start_date || null,
          //       expireDate: transaction?.expire_date || null,
          //       renewalDate: transaction?.renewal_date || null,
          //       isAutoRenew: transaction?.is_auto_renew || false,
          //       paymentMethod: transaction?.payment_method || null,
          //       amountPaid: transaction?.amount_paid || null,
          //       currency: transaction?.currency || null,
          //       discountApplied: transaction?.discount_applied || null,
          //       planType: transaction?.plan_type || null,
          //       cancelDate: transaction?.cancel_date || null,
          //       cancelReason: transaction?.cancel_reason || null,
          //       notes: transaction?.notes || null,
          //       userAgent: transaction?.user_agent || null,
          //       ipAddress: transaction?.ip_address || null,
          //       invoiceId: transaction?.invoice_id || null,
          //       planData: {
          //         planId: transaction?.plan_data?._id || null,
          //         planName: transaction?.plan_data?.plan_name || null,
          //         planSlug: transaction?.plan_data?.plan_slug || null,
          //         planDescription:
          //           transaction?.plan_data?.plan_description || null,
          //         planValues: transaction?.plan_data?.plan_values || [],
          //         monthlyPrice:
          //           transaction?.plan_data?.price_monthly?.value || null,
          //         yearlyPrice:
          //           transaction?.plan_data?.price_yearly?.value || null,
          //         discountMonthly:
          //           transaction?.plan_data?.price_monthly
          //             ?.discount_percentage || null,
          //         discountYearly:
          //           transaction?.plan_data?.price_yearly?.discount_percentage ||
          //           null,
          //         isTrialAvailable:
          //           transaction?.plan_data?.is_trial_available || false,
          //         trialPeriodDays:
          //           transaction?.plan_data?.trial_period_days || null,
          //       },
          //     })) || [],
          // },
          
        };
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error("Fetch user details error:", error);
        // Handle error cases as per your application's requirements
      },
    }),
  }),
});

export const { useFetchUserDetailsQuery } = getUser;
