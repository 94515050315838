import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  Select,
  FormControl,
  TextField,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFetchInboxRequirementBoardDetailsQuery } from "../../redux/api/Inbox/getInboxRequirementBoard";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import {
  resetCreateProjectState,
  setIsActive,
  setProjectName,
  setSelectedBoardId,
  setSelectedBusinessUserId,
  setSelectedSolutionId,
  setSelectedTeamMembers,
} from "../../redux/features/MyProjects/createProjectSlice";
import { useFetchTeamMemberListQuery } from "../../redux/api/Requirement Boards/getInviteListsDetail";
import { useFetchSolutionProviderRequirementBoardQuery } from "../../redux/api/SolutionProvider/getBsiCoLabSolutionList";
import { useFetchInboxBusinessUserListQuery } from "../../redux/api/SolutionProvider/Inbox/getBusinessUserList";
import { useCreateProjectMutation } from "../../redux/api/MyProjects/createProject";
import { toast } from "react-toastify";
import { setProjectId } from "../../redux/features/MyProjects/projectSlice";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { setRefetchProjectList } from "../../redux/features/refetch";

const AddProject = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const [shouldFetch, setShouldFetch] = useState(true);

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  //   States
  const {
    projectName,
    isActive,
    selectedBoardId,
    selectedSolutionId,
    selectedBusinessUserId,
    selectedTeamMembers,
  } = useSelector((state) => state.createProject);

  // validation state
  const [errors, setErrors] = useState({
    projectName: false,
    selectedBoardId: false,
    selectedSolutionId: false,
    selectedBusinessUserId: false,
    selectedTeamMembers: false,
  });

  // Api Calls
  const {
    data: boardList,
    isLoading: boardListLoading,
    error: boardListError,
    refetch: refetchBoard,
  } = useFetchInboxRequirementBoardDetailsQuery(
    { searchParam: "" },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    setShouldFetch(true); // Enable fetching on component mount
  }, []);

  const {
    data: solutionList,
    error: solutionListError,
    isLoading: solutionListLoading,
    refetch,
  } = useFetchInboxSelectedSolutionListQuery({
    // userId: userId,
    selectedBoardId: selectedBoardId,
    searchParams: "",
  });

  // solution listing for solution provider
  const {
    data: solutionBoardData,
    error: solutionBoardError,
    isLoading: solutionBoardLoading,
    refetch: refetchSolutionBoard,
  } = useFetchSolutionProviderRequirementBoardQuery({
    sort: "asc",
    solution_name: "",
  });

  // business user list for solution provider
  const {
    data: businessUserList,
    error: businessUserListError,
    isLoading: businessUserListLoading,
    refetch: refetchBusinessUserList,
  } = useFetchInboxBusinessUserListQuery({
    solutionId: selectedSolutionId,
  });

  // Team Member
  const {
    data: ListData,
    error: MemberListError,
    isLoading: TeamMemberLoading,
    refetch: refetchTeamMemberList,
  } = useFetchTeamMemberListQuery({
    searchParams: "",
  });

  // create project
  const [
    createProject,
    { isLoading: projectCreateLoading, isError: projectCreateError },
  ] = useCreateProjectMutation();

  // functions
  const handleProjectName = (event) => {
    const inputValue = event.target.value;
    dispatch(setProjectName(inputValue));
    if (inputValue !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, projectName: false }));
    }
  };

  const handleProjectStatus = (event) => {
    const status = event.target.value === "true";
    dispatch(setIsActive(status));
  };

  const handleRequirementBoardSelection = (event) => {
    const boardId = event.target.value;
    dispatch(setSelectedBoardId(boardId));
    if (boardId !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, selectedBoardId: false }));
    }
  };

  const handleSolutionSelection = (event) => {
    const solutionId = event.target.value;
    dispatch(setSelectedSolutionId(solutionId));
    if (solutionId !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, selectedSolutionId: false }));
    }
  };

  const handleBusinessUserId = (event) => {
    // const businessUserId = event.target.value;
    const { boardId, businessUserId } = JSON.parse(event.target.value);
    dispatch(setSelectedBusinessUserId(businessUserId));
    dispatch(setSelectedBoardId(boardId));
    if (userType !== "solution_provider" && businessUserId !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedBusinessUserId: false,
      }));
    }
    // console.log(boardId,"boardId")
    // console.log(businessUserId,"businessUserId")
  };
  const handleTeamMembers = (event) => {
    const inputValue = event.target.value;
    dispatch(setSelectedTeamMembers(inputValue));
  };

  const goToAllProject = () => {
    navigate("/projects");
  };

  const createNewProject = async () => {
    let hasError = false;
    const newErrors = {
      projectName: false,
      selectedSolutionId: false,
      selectedBusinessUserId: false,
      selectedBoardId: false,
    };

    if (projectName === "") {
      newErrors.projectName = true;
      hasError = true;
    }

    if (selectedSolutionId === "") {
      newErrors.selectedSolutionId = true;
      hasError = true;
    }

    if (userType === "solution_provider" && selectedBusinessUserId === "") {
      newErrors.selectedBusinessUserId = true;
      hasError = true;
    }
    if (userType === "business_user" && selectedBoardId === "") {
      newErrors.selectedBoardId = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await createProject({
        projectName,
        isActive,
        selectedSolutionId,
        userId,
        selectedBusinessUserId,
        selectedBoardId,
        selectedTeamMembers,
      });
      const successMessage = response?.data?.message || "Project created";
      showToastMessage(successMessage, "success");
      dispatch(setProjectId(response?.data?.data?.project?._id));
      dispatch(setRefetchProjectList(true));
      if (response?.statusCode === 500) {
        showToastMessage(successMessage, "error");
      }
    } catch (projectCreateError) {
      const errorMessage = projectCreateError?.message || "Failed to create";
      showToastMessage(errorMessage, "error");
    } finally {
      dispatch(resetCreateProjectState());
      goToAllProject();
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (projectCreateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [projectCreateLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleGoBack}
            sx={{
              marginRight: "5px",
              display: "inline-flex",
              p: 0,
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "32px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: "1.4rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            Create Project
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        //
        columnSpacing={2}
        sx={{
          // p:2,
          mt: 1,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("xl")]: {
            // p: 10,
            mt: 8,
          },
        }}
      >
        <Grid item xs={12} md={11} lg={11}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              mb: 3,
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                p: 4,
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
              }}
              style={{
                paddingBottom: "0",
              }}
            >
              <Box
                component="form"
                sx={{
                  mt: 0,
                  mb: 0,
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={3}
                  sx={{
                    mb: 1,
                  }}
                >
                  {/* Project Name */}
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Project Name*
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="projectField"
                      value={projectName}
                      onChange={handleProjectName}
                      variant="filled"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                    />
                    {errors.projectName && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 0.5 }}
                      >
                        This field is required.
                      </Typography>
                    )}
                  </Grid>
                  {/* Status */}
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Status
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={isActive}
                        onChange={handleProjectStatus}
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                          background: "#ECF6FF",
                          "& .MuiSelect-select": {
                            minHeight: "20px",
                            padding: "11px 14px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "0.9rem",
                            border: "solid 1px #DBEBF8",
                          },
                          "& .MuiSelect-icon": {
                            color: "lightSecondary.main",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: "300px",
                              overflowY: "auto",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#9C9C9C",
                                borderRadius: "4px",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value={"true"}>Active</MenuItem>
                        <MenuItem value={"false"}>InActive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {userType == "solution_provider" ? (
                    <>
                      {/* Select Solution */}
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Select Solution*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSolutionId} // Ensure value is always an array
                            onChange={handleSolutionSelection} // Update the state
                            displayEmpty
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            {solutionBoardLoading ? (
                              <MenuItem disabled>Loading...</MenuItem>
                            ) : solutionBoardData &&
                              solutionBoardData?.solutions &&
                              solutionBoardData.solutions.length > 0 ? (
                              solutionBoardData.solutions
                                .slice() // Create a copy to avoid mutating the original array
                                .reverse() // Reverse the array
                                .map((solution, index) => (
                                  <MenuItem
                                    key={solution._id}
                                    value={solution?._id}
                                  >
                                    {solution?.solution_name}
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem>No Solution Available</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {errors.selectedSolutionId && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                      {/* Select Business */}
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Select Business*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={selectedBusinessUserId}
                            value={
                              selectedBusinessUserId && selectedBoardId
                                ? JSON.stringify({
                                    boardId: selectedBoardId,
                                    businessUserId: selectedBusinessUserId,
                                  })
                                : ""
                            }
                            onChange={handleBusinessUserId} // Update the state
                            displayEmpty
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            {businessUserList &&
                            businessUserListLoading &&
                            businessUserList?.length > 0 ? (
                              <MenuItem disabled>Loading...</MenuItem>
                            ) : businessUserList &&
                              businessUserList?.length > 0 ? (
                              businessUserList.map((business) => (
                                <MenuItem
                                  key={business._id}
                                  // value={business?._id}
                                  value={JSON.stringify({
                                    boardId: business?.board_id,
                                    businessUserId:
                                      business.userProfile?.user_id,
                                  })}
                                >
                                  {business?.userProfile?.business_name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                No Business Available
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {errors.businessUserId && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* Select Board */}
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Select Requirement*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBoardId} // Ensure value is always an array
                            onChange={handleRequirementBoardSelection} // Update the state
                            displayEmpty
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            {boardListLoading ? (
                              <MenuItem>Loading...</MenuItem>
                            ) : boardList &&
                              boardList?.boards &&
                              boardList.boards.length > 0 ? (
                              boardList.boards.map((board, index) => (
                                <MenuItem key={board._id} value={board?._id}>
                                  {board?.board_name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>No Board Available</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {errors.selectedBoardId && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                      {/* Select Solution */}
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Select Solution*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSolutionId} // Ensure value is always an array
                            onChange={handleSolutionSelection} // Update the state
                            displayEmpty
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            {solutionList &&
                            solutionListLoading &&
                            solutionList?.solutions?.solutions.length > 0 ? (
                              <MenuItem>Loading...</MenuItem>
                            ) : solutionList &&
                              solutionList?.solutions?.solutions &&
                              solutionList?.solutions?.solutions.length > 0 ? (
                              solutionList?.solutions?.solutions.map(
                                (solution, index) => (
                                  <MenuItem
                                    key={solution?.solution_id?._id}
                                    value={solution?.solution_id?._id}
                                  >
                                    {solution?.solution_id?.solution_name}
                                  </MenuItem>
                                )
                              )
                            ) : (
                              <MenuItem>No Solution Available</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {errors.selectedSolutionId && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {/* Add Collaborators */}
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Add Team Members
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={selectedTeamMembers}
                        onChange={handleTeamMembers}
                        multiple
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                          background: "#ECF6FF",
                          "& .MuiSelect-select": {
                            minHeight: "20px",
                            padding: "11px 14px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "0.9rem",
                            border: "solid 1px #DBEBF8",
                          },
                          "& .MuiSelect-icon": {
                            color: "lightSecondary.main",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: "300px",
                              overflowY: "auto",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#9C9C9C",
                                borderRadius: "4px",
                              },
                            },
                          },
                        }}
                      >
                        {!ListData || ListData.length > 0 === 0 ? (
                          <MenuItem disabled>No User Available</MenuItem>
                        ) : (
                          ListData.map((user) => (
                            <MenuItem key={user._id} value={user?.user_id}>
                              {user?.first_name} {user?.last_name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Save and Next Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <Button
                      disabled={projectCreateLoading}
                      variant="contained"
                      sx={{
                        textTransform: "inherit",
                        p: "10px 20px",
                        boxShadow: "none",
                        fontWeight: "600",
                        width: "200px",
                      }}
                      onClick={createNewProject}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddProject;
